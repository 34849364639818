<template>
  <div class="card h-full">
    <div class="title flex items-center justify-between">
      <h5>{{ data.title }}</h5>
      <div class="text-sm font-normal">
        <span class="uppercase">{{ data.currency }}</span>: {{ total }}
      </div>
    </div>
    <hr class="border-gray-300 dark:border-gray-600" />
    <div class="body">
      <div>
        <div
          class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
          <table class="mb-2 table table-sm select-none border-t border-gray-200 dark:border-gray-700">
            <thead>
              <tr>
                <th>{{ translate('markets.trading.wall.price') }}</th>
                <th>{{ translate('markets.trading.wall.amount') }}</th>
                <th>{{ translate('markets.trading.wall.total') }}</th>
                <th>{{ translate('markets.trading.wall.sum') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length">
                <tr v-for="record in records" :key="record.ID" :class="`${record.isUpdated ? 'glow' : ''}`"
                  @click="populate(record)">
                  <td>{{ numToPlain(record.Price) }}</td>
                  <td>{{ numToPlain(record.Amount) }}</td>
                  <td>{{ numToPlain(record.Total) }}</td>
                  <td>{{ numToPlain(record.Sum) }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="4">{{ translate('markets.wallet.open_orders.empty') }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :meta="meta" @page="updatePage"></pagination>
      </div>
    </div>
  </div>
</template>

<script setup>
import Pagination from '@/components/common/Pagination.vue';
import { computed, inject, ref, watch } from 'vue';
import compositionUtils from '@/compositionUtils';

const props = defineProps({
  data: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {
      data: [],
      title: '',
      currency: '',
      type: ''
    }
  }
});

const emit = defineEmits([
  'populate'
]);

const translate = inject('translate');

const { numToPlain } = compositionUtils();

watch(() => props.data, (val) => {
  if (val.data && val.data.length > 0) {
    meta.value = {
      currentPage: 1,
      limit: 20,
      offset: 0,
      totalPages: 0
    };
    meta.value.totalPages = Math.ceil(val.data.length / meta.value.limit);
  }
}, {
  deep: true
});

const records = computed(() => {
  return props.data.data.slice(
    meta.value.offset,
    meta.value.currentPage * meta.value.limit
  );
});
const total = computed(() => {
  let total = 0;
  props.data.data.forEach(r => {
    total += props.data.type === 'sell' ? Number(r.Amount) : Number(r.Total);
  });
  return Number(total).toFixed(8);
});

const meta = ref({
  currentPage: 1,
  limit: 20,
  offset: 0,
  totalPages: 0
});

const updatePage = (page) => {
  meta.value = {
    ...meta.value,
    currentPage: page,
    offset: (page - 1) * meta.value.limit
  };
};

const populate = (record) => {
  emit('populate', {
    record,
    type: props.data.type
  });
};

</script>

<style scoped></style>
