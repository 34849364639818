<template>
  <div>
    <template v-if="!isProcessing">
      <div class="flex justify-end mb-3">
        <div class="relative">
          <icon classes="absolute w-5 h-5 top-3 left-3 dark:text-gray-300" name="search"></icon>
          <input v-model="keyword" aria-label="Search" class="form-input px-10 w-full" type="text" />
          <span v-show="keyword !== ''" class="absolute top-3 right-3" @click="keyword = ''">
            <icon classes="w-5 h-5 dark:text-gray-300" name="close"></icon>
          </span>
        </div>
      </div>
      <div
        class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
        <table class="table-sm mb-2 border-t border-gray-200 dark:border-gray-700">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>ID</th>
              <th>{{ translate('invoice.datetime') }}</th>
              <th>{{ translate('invoice.coin') }}</th>
              <th>{{ translate('invoice.amount') }}</th>
              <th>{{ translate('invoice.status') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="filteredTransactions.length > 0">
              <template v-for="(transaction, index) in paginatedTransactions" :key="transaction.ID">
                <tr @click="toggleIndex = (toggleIndex === index ? -1 : index)">
                  <td>
                    <span>
                      <icon :name="toggleIndex === index ? 'minus-circle' : 'plus-circle'" classes="w-5 h-5 mx-auto">
                      </icon>
                    </span>
                  </td>
                  <td>{{ transaction.ID }}</td>
                  <td>
                    <span class="flex">
                      {{ transaction.CreatedOn }}
                    </span>
                  </td>
                  <td>{{ transaction.Name }}</td>
                  <td>{{ transaction.LongName + ' ' + toFixed(transaction.Amount) }}</td>
                  <td @click="toggleIndex = (toggleIndex === index ? -1 : index)">
                    <div class="flex items-center">
                      <span :class="`w-2 h-2 inline-block mr-2 rounded-full ${statusColor(transaction.Status)}`"></span>
                      <span class="inline-block" @click="keyword = transaction.Status">{{ transaction.Status }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="toggleIndex === index">
                  <td colspan="6">
                    <table class="inner-table table-sm text-left">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <a class="flex items-center" v-if="transaction.ConfirmCode !== ''"
                              :href="`https://cointopay.com/invoice/${transaction.ConfirmCode}`" target="_blank">
                              <Icon name="barcode" classes="w-5 h-5 mr-2" />
                              <span v-html="decode(transaction.CoinAddressUsed)"></span>
                            </a>
                            <span class="text-wrap" v-else>{{ transaction.CoinAddressUsed }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.coin') }}</th>
                          <td>{{ transaction.Name }}</td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.status') }}</th>
                          <td>
                            <span
                              :class="`w-2 h-2 inline-block mr-2 rounded-full ${statusColor(transaction.Status)}`"></span>
                            {{ transaction.Status }}
                          </td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.created') }}</th>
                          <td>{{ transaction.CreatedOn }}</td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.confirmed') }}</th>
                          <td>{{ transaction.TransactionConfirmedOn }}</td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.amount') }}</th>
                          <td>{{ toFixed(transaction.Amount) + ' ' + transaction.LongName }}</td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.input_amount') }}</th>
                          <td>{{ toFixed(transaction.OriginalAmount) }} {{ transaction.InputCurrency }}</td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.reference') }}</th>
                          <td>
                            <div class="flex items-center">
                              <span class="whitespace-normal break-all" v-html="transaction.CustomerReferenceNr"></span>
                              <span class="ml-2 cursor-pointer"
                                @click="copyToClipboard(transaction.CustomerReferenceNr)">
                                <icon classes="w-5 h-5" name="clipboard"></icon>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="transaction.Tag">
                          <th>Memo/Tag</th>
                          <td>
                            <div class="flex items-center">
                              <span>{{ transaction.Tag }}</span>
                              <span class="ml-2 cursor-pointer" @click="copyToClipboard(transaction.Tag)">
                                <icon classes="w-5 h-5" name="clipboard"></icon>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{{ translate('invoice.expiry_on') }}</th>
                          <td>{{ transaction.ExpiredOn }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td class="text-center" colspan="6">No transactions available</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <template v-if="filteredTransactions.length > 0">
        <pagination :meta="meta" @page="updatePage"></pagination>
      </template>
    </template>
    <template v-else>
      <pulse-skeleton></pulse-skeleton>
      <pulse-skeleton></pulse-skeleton>
    </template>
  </div>
</template>

<script setup>
import PulseSkeleton from '@/components/PulseSkeleton';
import Icon from '@/components/Icon';
import Pagination from '@/components/common/Pagination';
import includes from 'lodash/includes';
import { useStore } from 'vuex';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import compositionUtils from '@/compositionUtils';

const props = defineProps([
  'refresh',
  'tab'
]);

// Providers
const translate = inject('translate');
const http = inject('http');

// Store
const store = useStore();

const {
  lowercase,
  copyToClipboard,
  toFixed
} = compositionUtils();

const transactions = ref([]);
const keyword = ref('');
const isProcessing = ref(false);
const meta = ref({
  currentPage: 1,
  totalPages: 0,
  limit: 20,
  offset: 0
});
const toggleIndex = ref(-1);

// Computed
const user = computed(() => store.state.user);

watch(() => props.refresh, (val) => {
  if (val && props.tab === 'transactions') {
    fetchTransactions(false);
  }
});

const filteredTransactions = computed(() => {
  const transactionsList = transactions.value.filter(t => {
    if (keyword.value) {
      const k = keyword.value.trim().toLowerCase();
      return (includes(t.Name.toLowerCase(), k) ||
        includes(lowercase(t.ID), k) ||
        includes(lowercase(t.CustomerReferenceNr), k) ||
        includes(lowercase(t.Status), k) ||
        includes(lowercase(t.Amount), k) ||
        includes(lowercase(t.InputCurrency), k) ||
        includes(lowercase(t.LongName), k) ||
        includes(lowercase(t.OriginalAmount), k) ||
        includes(lowercase(t.CoinAddressUsed), k) ||
        includes(lowercase(t.ConfirmCode), k) ||
        includes(t.CreatedOn, k) ||
        includes(t.ExpiredOn, k) ||
        includes(t.TransactionConfirmedOn, k) ||
        includes(t.ID, k)
      );
    }
    return true;
  });
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  meta.value.totalPages = Math.ceil(transactionsList.length / meta.value.limit);
  return transactionsList;
});
const paginatedTransactions = computed(() => {
  if (filteredTransactions.value.length > 0) {
    return filteredTransactions.value.slice(
      meta.value.offset,
      meta.value.currentPage * meta.value.limit
    );
  }
  return filteredTransactions.value;
});

// Methods
const apiBaseUrl = process.env.VUE_APP_API_URL;
const statusColor = (status) => {
  status = status.toLowerCase();
  let color = 'bg-blue-500';
  switch (status) {
    case 'waiting':
    case 'underpaid':
    case 'ltr-open':
    case 'vtoken-active':
    case 'scheduled':
    case 'awaiting-fiat':
      color = 'bg-yellow-500';
      break;
    case 'expired':
    case 'ltr-expired':
      color = 'bg-red-500';
      break;
    case 'paid':
    case 'ltr-closed':
    case 'payout':
    case 'vtoken-claimed':
      color = 'bg-green-500';
      break;
  }
  return color;
};
const fetchTransactions = (isLoader = true) => {
  if (user.value === null) {
    return;
  }
  if (isLoader) {
    isProcessing.value = true;
  }
  http.get(`${apiBaseUrl}/v2REAPI`, {
    params: {
      Call: 'Transactions',
      MerchantID: user.value?.ID,
      APIKey: user.value?.APIKey,
      output: 'json'
    }
  }).then(response => {
    isProcessing.value = false;
    if (response.status === 200) {
      transactions.value = typeof response.data.result === 'undefined' ? response.data.data : [];
      if (transactions.value) {
        meta.value.totalPages = Math.ceil(transactions.value.length / meta.value.limit);
      }
    }
  }).catch(error => {
    isProcessing.value = false;
    console.log(error);
  });
};
const updatePage = (page) => {
  meta.value = {
    ...meta.value,
    currentPage: page,
    offset: (page - 1) * meta.value.limit
  };
};
const decode = (str) => {
  return decodeURIComponent(str);
};

onBeforeMount(() => {
  fetchTransactions();
});

</script>

<style scoped lang="scss">
.inner-table {
  @apply w-full;

  tr {

    td:first-child,
    th:first-child {
      @apply pl-2;
    }

    &:last-child {
      @apply border-b-0;
    }
  }
}

// Link
a {
  @apply text-blue-500 hover:underline;
}
</style>
