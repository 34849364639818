import Login from '@/views/Login.vue';
import Markets from '@/views/Markets.vue';
import TradeView from '@/views/TradeView.vue';
import ExchangeWallet from '@/views/ExchangeWallet.vue';
import Withdraw from '@/views/Withdraw.vue';

export const exchangeRoutes = [
  {
    path: '/',
    name: 'home',
    component: Markets,
    meta: {
      title: 'Reflextrader'
    }
  },
  {
    path: '/markets',
    name: 'markets',
    component: Markets,
    meta: {
      title: 'Reflextrader'
    }
  },
  {
    path: '/tradeview/:market',
    name: 'market',
    component: TradeView,
    meta: {
      title: 'Reflextrader'
    }
  },
  {
    path: '/trading/:tab?',
    name: 'trading',
    component: ExchangeWallet,
    meta: {
      title: 'Reflextrader'
    }
  },
  {
    path: '/trading/withdraw',
    name: 'withdraw',
    component: Withdraw,
    meta: {
      title: 'Reflextrader'
    }
  },
  {
    path: '/account/:action?',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: 'account' */ '@/views/Account.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reflextrader'
    }
  },
  {
    path: '/trade-bots',
    name: 'trade-bots',
    component: () =>
      import(/* webpackChunkName: 'trade-bots' */ '@/views/TradeBots.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Reflextrader | Login'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import(/* webpackChunkName: 'signup' */ '@/views/Signup.vue'),
    meta: {
      title: 'Reflextrader | Sign Up'
    }
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: () =>
      import(
        /* webpackChunkName: 'recover-password' */ '@/views/ForgotPassword.vue'
      ),
    meta: {
      title: 'Reflextrader | Recover Password'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: 'about' */ '@/views/AboutUs.vue'),
    meta: {
      title: 'Reflextrader | About'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () =>
      import(/* webpackChunkName: 'terms' */ '@/views/Terms.vue'),
    meta: {
      title: 'Reflextrader | Terms'
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () =>
      import(/* webpackChunkName: 'payments' */ '@/views/Payments.vue'),
    meta: {
      title: 'Reflextrader | Payments'
    }
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: () =>
      import(/* webpackChunkName: 'newsletter' */ '@/views/Newsletter.vue'),
    meta: {
      title: 'Reflextrader | Newsletter'
    }
  },
  {
    path: '/system-status',
    name: 'system-status',
    component: () =>
      import(
        /* webpackChunkName: 'system-status' */ '@/views/SystemStatus.vue'
      ),
    meta: {
      title: 'Reflextrader | System Status'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];
