<template>
  <div>
    <div
      class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
      <table class="mb-2 table table-sm select-none border-t border-gray-200 dark:border-gray-700">
        <thead>
          <tr>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.date') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.coin') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.type') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.price') }} ({{ baseMarket.name }})</th>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.amount') }} ({{ market.ShortName }})
            </th>
            <th class="whitespace-nowrap">{{ translate('markets.history.history.total') }} ({{ baseMarket.name }})</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!isLoading">
            <template v-if="orders.length">
              <tr v-for="order in paginatedOrders" :key="order.id">
                <td>{{ order.CreatedOn }}</td>
                <td>{{ order.LongName }}</td>
                <td class="uppercase"
                  :class="`${order.TradeFlexTypeID === 1 ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'}`">
                  {{ order.TradeFlexTypeID === 1 ? translate('markets.wallet.open_orders.buy') :
              translate('markets.wallet.open_orders.sell') }}
                </td>
                <td>{{ numToPlain(order.Price) }}</td>
                <td>{{ numToPlain(order.Amount) }}</td>
                <td>{{ numToPlain(order.Total) }}</td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td colspan="6">{{ translate('markets.wallet.open_orders.empty') }}</td>
              </tr>
            </template>
          </template>

          <template v-else>
            <tr v-for="i in 5" :key="i">
              <td colspan="6">
                <line-skeleton></line-skeleton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <pagination :meta="meta" @page="updatePage"></pagination>
  </div>
</template>

<script setup>
import Pagination from '@/components/common/Pagination.vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import compositionUtils from '@/compositionUtils';
import LineSkeleton from '@/components/LineSkeleton.vue';

const store = useStore();

const translate = inject('translate');
const http = inject('http');

const props = defineProps([
  'isMarket',
  'refresh'
]);

const market = computed(() => store.state.market);
const user = computed(() => store.state.user);
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);

const { numToPlain, baseMarketName } = compositionUtils();

const isLoading = ref(false);
const orders = ref([]);

watch(() => props.refresh, (val) => {
  if (val) {
    fetchHistory(false);
  }
});

const meta = ref({
  currentPage: 1,
  limit: 20,
  offset: 0,
  totalPages: 0
});

const paginatedOrders = computed(() => {
  if (orders.value.length > 0) {
    return orders.value.slice(
      meta.value.offset,
      meta.value.currentPage * meta.value.limit
    );
  }
  return orders.value;
});

const updatePage = (page) => {
  meta.value = {
    ...meta.value,
    currentPage: page,
    offset: (page - 1) * meta.value.limit
  };
};

const fetchHistory = (isLoader = true) => {
  if (isLoader) {
    isLoading.value = true;
  }
  const params = {
    Call: !props.isMarket && user.value ? 'ShowMyTradeHistory' : 'ShowTradeHistory',
    Output: 'json',
    AltCoinID: market.value.AltCoinID,
    MerchantID: !props.isMarket && user.value ? user.value.ID : 1,
    APIKey: !props.isMarket && user.value ? user.value.APIKey : '_',
    BaseMarket: baseMarketName(baseMarket.value.name)
  };
  const endPoint = 'https://artemis.cointopay.com/trading/';

  http.get(endPoint, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    isLoading.value = false;
    orders.value = response.data.data;
    if (orders.value) {
      meta.value.totalPages = Math.ceil(orders.value.length / meta.value.limit);
    }
  }).catch(error => {
    isLoading.value = false;
    console.log(error);
  });
};

onBeforeMount(() => {
  fetchHistory();
});

</script>

<style scoped></style>
