<template>
  <div>
    <Navbar />
    <div class="dark:bg-gray-600" style="margin-top:60px; min-height: calc(100vh - 314px)">
      <router-view />
    </div>
    <ShopFooter v-if="sitename === 'ecom'" />
    <Footer v-else />
  </div>
</template>

<style lang="scss"></style>
<script setup>
import Navbar from './components/partials/Navbar';
import Footer from './components/partials/Footer';
import {
  FETCH_ALT_COINS,
  FETCH_COUNTRIES,
  FETCH_LANGUAGES,
  FETCH_TRANSFER_WISE_CONFIG,
  SET_NOTIFICATIONS,
  FETCH_ACCOUNT_INFO,
  PERSIST_AUTH,
  SET_LOGIN_TYPE
} from './store/keys';
import { computed, inject, onBeforeMount, onBeforeUnmount, reactive } from 'vue';
import { useStore } from 'vuex';
import ShopFooter from '@/components/partials/ShopFooter.vue';
import { useCookies } from 'vue3-cookies';
import { useRoute, useRouter } from 'vue-router';

const toast = inject('toast');

const store = useStore();

const { cookies } = useCookies();
// Route
const router = useRouter();
const route = useRoute();

const state = reactive({
  socket: null,
  isSocketClosed: false
});

const notifications = computed(() => store.state.notifications);
const user = computed(() => store.state.user);
const sitename = computed(() => store.state.sitename);

const generateNotification = (parts) => {
  let message = '';
  let prefix = 'T';
  let id = null;
  switch (parts[2]) {
    case 'PAID':
      prefix = 'T';
      id = prefix + parts[1].padStart(9, '0');
      message = `${id} paid successfully`;
      break;
    case 'COMPLETE':
      prefix = 'T';
      id = prefix + parts[1].padStart(9, '0');

      if (parts[3] === 'NOTENOUGH') {
        message = `${id} is underpaid`;
      } else {
        message = `${id} is paid`;
      }
      break;
    case 'EXPIRED':
      prefix = 'T';
      id = prefix + parts[1].padStart(9, '0');
      message = `${id} has expired`;
      break;
    case 'PAYOUT':
      prefix = 'P';
      id = prefix + parts[1].padStart(9, '0');
      message = `${id} payout successful`;
  }
  return {
    message,
    id,
    date: new Date()
  };
};

const getType = (status) => {
  let type = 'info';
  if (status === 'paid' || status === 'paidout') {
    type = 'success';
  } else if (status === 'waiting' || status === 'underpaid') {
    type = 'warning';
  } else if (status === 'cancelled' || status === 'expired') {
    type = 'error';
  }
  return type;
};

const showToast = (status, message) => {
  const type = getType(status.toLowerCase());
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'error':
      toast.error(message);
      break;
    case 'warning':
      toast.warning(message);
      break;
    case 'info':
      toast.info(message);
      break;
  }
};

const initSocket = () => {
  state.socket = new WebSocket('wss://wss.cointopay.com/balance');
  state.socket.onmessage = (event) => {
    if (event && event.data) {
      const parts = event.data.split(':');
      if (parts.length >= 4 && user.value) {
        if (parts[0].toString() === user.value.ID.toString()) {
          const notificationsList = notifications.value;
          const notification = generateNotification(parts);
          notificationsList.splice(0, 0, notification);
          store.commit(SET_NOTIFICATIONS, notifications);
          showToast(parts[2], `Transaction ${parts[1]} ${parts[2]}`);
        }
      }
    }
  };
  state.socket.onclose = (event) => {
    if (!state.isSocketClosed) {
      try {
        initSocket();
      } catch (error) {
        console.log('App Notifications was not able to open a WebSocket connection to balance');
      }
    }
  };
};

const checkMarkets = () => {
  const baseMarkets = store.state.baseMarkets;
  if (baseMarkets.length < 4) {
    store.commit('SELECT_BASE_MARKET', [
      { id: 1, name: 'BTC', fullName: 'bitcoin', isSelected: true },
      { id: 2, name: 'LTC', fullName: 'litecoin', isSelected: false },
      { id: 9, name: 'Doge', fullName: 'dogecoin', isSelected: false },
      { id: 726, name: 'EURx', fullName: 'banckrypto', isSelected: false }
    ]);
  }
};

const persistUser = (user) => {
  delete user.Status;
  // Set cookie
  cookies.set('JSESSIONID', user.SessionID, {
    expires: '1Y',
    domain: '.cointopay.com'
  });

  store.dispatch(PERSIST_AUTH, user).then(() => {
    // Get account info
    store.dispatch(FETCH_ACCOUNT_INFO).then(response => {
      store.commit(SET_LOGIN_TYPE, false);
    }).catch(error => {
      console.log(error.response);
    });
  });
};

onBeforeMount(() => {
  setTimeout(() => {
    if (Object.keys(route.query).length > 0 && route.query?.s !== '') {
      let u = atob(route.query.s);
      if (u) {
        u = JSON.parse(u);
      }
      persistUser(u);
      router.replace({
        name: router.currentRoute.name,
        query: {},
        params: router.currentRoute.params
      });
    }
  }, 0);
  initSocket();
  const mode = computed(() => store.state.mode);
  if (mode.value === 'dark') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
  // Fetch languages
  store.dispatch(FETCH_LANGUAGES);
  // Fetch alt coins
  store.dispatch(FETCH_ALT_COINS);
  // Fetch transfer wise configurations
  store.dispatch(FETCH_TRANSFER_WISE_CONFIG);
  // Fetch countries
  store.dispatch(FETCH_COUNTRIES);
  checkMarkets();
});

onBeforeUnmount(() => {
  if (state.socket) {
    state.isSocketClosed = true;
    state.socket.close();
  }
});
</script>

<style lang="scss">
.badge {
  @apply text-xs font-medium mr-2 px-2.5 py-0.5 rounded cursor-pointer;
}

.badge-primary {
  @apply bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300;
}

.badge-dark {
  @apply bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300;
}

.badge-danger {
  @apply bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300;
}

.badge-success {
  @apply bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300;
}
</style>
