<template>
  <TabSet full="" @changed="updateRoute">
    <Tab :name="translate('markets.history.tabs.my_orders')" slug="my-orders" v-if="user">
      <open-orders :refresh="props.refresh.myOrders || timer" :wallId="wallId" :all="false" :limit="10" :tab="tab"
        @populateForm="populateForm"></open-orders>
    </Tab>
    <Tab :name="translate('markets.history.tabs.my_history')" slug="trading-history" v-if="user">
      <trading-history :isMarket="false" :refresh="props.refresh.tradingHistory"></trading-history>
    </Tab>
    <Tab :name="translate('markets.history.tabs.market_history')" slug="history">
      <trading-history :isMarket="true" :refresh="props.refresh.tradingHistory"></trading-history>
    </Tab>
  </TabSet>
</template>

<script setup>

import TabSet from '@/components/common/TabSet.vue';
import Tab from '@/components/common/Tab.vue';
import { computed, inject, ref } from 'vue';
import OpenOrders from '@/components/exchange-wallet/OpenOrders.vue';
import TradingHistory from '@/components/trade-view/TradingHistory.vue';
import { useStore } from 'vuex';

const props = defineProps(['refresh', 'wallId']);

const emit = defineEmits([
  'populateForm'
]);

const store = useStore();

const translate = inject('translate');
const timer = ref(null);
const tab = ref('my-orders');

const user = computed(() => store.state.user);

const updateRoute = (t) => {
  tab.value = t.tab.slug;
  timer.value = (new Date()).getTime() * Math.random();
};

const populateForm = (data) => {
  emit('populateForm', data);
};

</script>

<style scoped></style>
