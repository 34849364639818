<template>

  <div class="bg-gray-300 dark:bg-gray-700 p-4" v-if="data">
    <div class="max-w-7xl 3xl:max-w-8xl mx-auto">
      <div class="md:flex justify-between">
        <div class="mb-4 md:mb-0">
          <div class="flex items-center gap-2" v-if="data">
            <img v-if="data.Avatar && data.Avatar !== './pics/question.jpg'" :alt="data.ShopTitle" class="h-12"
              :src="data.Avatar" />
            <span>{{ data.ShopTitle }}</span>
          </div>
          <img alt="Cointopay" class="mr-3 h-10" :src="logo" v-else />
        </div>
        <div class="text-white mb-4 md:mb-0">
          <h2 class="uppercase text-lg font-bold" v-if="networks.length">{{ translate('misc.social') }}</h2>
          <ul class="social">
            <li v-for="(network, index) in networks" :key="index">
              <a :class="nameToClass(network.name)" :href="network.url"
                class="m-1 w-12 h-12 border-2 border-white rounded flex items-center justify-center" target="_blank">
                <img :src="require(`../../assets/images/networks/${network.icon}.svg`)" alt="" class="w20" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-center mt-2">
        <template v-if="!isLinksAvailable">
          <div class="text-center mb-3">
            <p>{{ data?.ShopTitle || 'Posercart.com' }} | Contact: <a
                href="mailto:support@posercart.com">support@posercart.com</a>
              | Call +00-00-000-0000</p>
            <p>Address: Street No, Zip Code, City, State, Country</p>
            <img alt="Credit Card Logos" title="Credit Card Logos"
              src="https://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_4.gif" width="116"
              height="35" border="0" class="mx-auto my-3">
            <p>Copyright {{ (new Date()).getFullYear() }} {{ data?.ShopTitle }}, All Rights Reserved</p>
          </div>
          <ul class="text-gray-800 dark:text-gray-100 p-0 inline-block links">
            <li>
              <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a>
            </li>
            <li>
              <a href="/refund-return" target="_blank">Refund & Return Policy</a>
            </li>
            <li>
              <a href="/shipping-policy" target="_blank">Shipping Policy</a>
            </li>
          </ul>
        </template>
        <div class="footer-ctn" v-else v-html="decodeHtmlEntities(data?.SocialDescription)"></div>
      </div>
    </div>
  </div>
  <div class="bg-gray-900 p-2 text-white">
    <div class="max-w-7xl 3xl:max-w-8xl mx-auto">
      <div class="text-center text-sm">
        <p v-if="data">
          Powered by <a href="https://ebfml.com" target="_blank" class="underline cursor-pointer">
            EBRIGHT FUTURE MARKETS LIMITED
          </a>
        </p>
        <p v-else>Copyright {{ (new Date()).getFullYear() }}. Posercart.com. All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { SET_SITE_DATA } from '@/store/keys';
import { useRoute } from 'vue-router';

// Providers
const translate = inject('translate');
const http = inject('http');

const route = useRoute();
const store = useStore();

const data = ref(null);
const networks = ref([]);
const isLinksAvailable = ref(false);

const setNetworks = (data) => {
  if (data && data.SocialDescription !== 'null' && data.SocialDescription !== '' && data.SocialDescription.toUpperCase() !== 'OK') {
    isLinksAvailable.value = true;
  }
  networks.value = [];
  if (data) {
    if (data.SocialTwitter !== '' && data.SocialTwitter !== 'null') {
      networks.value.push({
        name: 'twitter',
        url: data.SocialTwitter,
        icon: 'twitter'
      });
    }
    if (data.SocialFacebook !== '' && data.SocialFacebook !== 'null') {
      networks.value.push({
        name: 'facebook',
        url: data.SocialFacebook,
        icon: 'facebook'
      });
    }
    if (data.SocialReddit !== '' && data.SocialReddit !== 'null') {
      networks.value.push({
        name: 'reddit',
        url: data.SocialReddit,
        icon: 'reddit'
      });
    }
    if (data.SocialLinkedIn !== '' && data.SocialLinkedIn !== 'null') {
      networks.value.push({
        name: 'linkedin',
        url: data.SocialLinkedIn,
        icon: 'linkedin'
      });
    }
  }
};

watch(() => route.params, (newVal) => {
  data.value = store.state.siteData || null;
  if (newVal && (newVal.shopId || newVal.id)) {
    const merchantID = newVal?.shopId || newVal.id;
    if (data.value === null || data.value.ID !== merchantID.toString()) {
      const baseUrl = process.env.VUE_APP_API_URL;
      const url = `${baseUrl}/v2REAPI?Call=shopdetails&MerchantID=${merchantID}&APIKey=_&output=json`;
      http.get(url).then(response => {
        data.value = response.data[0];
        store.commit(SET_SITE_DATA, data.value);
      }).catch(error => {
        console.log(error);
      });
    }
  }
}, {
  immediate: true,
  deep: true
});

watch(() => data.value, (val) => {
  if (val) {
    setNetworks(val);
  }
}, {
  immediate: true,
  deep: true
});

const nameToClass = (value) => {
  return value.trim().toLowerCase().replace(/ /g, '-');
};

const theme = computed(() => store.state.mode);
const logo = computed(() => {
  return theme.value === 'light' ? require('@/assets/logo-dark.svg') : require('@/assets/logo-light.svg');
});

const decodeHtmlEntities = (str) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};
</script>

<style scoped lang="scss">
ul.social {
  list-style-type: none;
  padding: 0;
  max-width: 280px;

  li {
    display: inline-block;

    a {
      &.twitter:hover {
        background: #099bcc;
        border-color: #099bcc;
      }

      &.linkedin:hover {
        background: #2e8cc2;
        border-color: #2e8cc2;
      }

      &.facebook:hover {
        background: #4672b3;
        border-color: #4672b3;
      }

      &.pinterest:hover {
        background: #c32524;
        border-color: #c32524;
      }

      &.reddit:hover {
        background: #ff4500;
        border-color: #ff4500;
      }

      &.youtube:hover {
        background: #cb322c;
        border-color: #cb322c;
      }

      &.github:hover {
        background: #333637;
        border-color: #333637;
      }

      &.rss:hover {
        background: #e97633;
        border-color: #e97633;
      }

      &.telegram:hover {
        background: #2AB5F6;
        border-color: #2AB5F6;
      }

      &.discord:hover {
        background: #8799F7;
        border-color: #8799F7;
      }

      img {
        width: 24px;
      }
    }
  }
}

:deep(.links) {
  li {
    @apply inline mx-1;

    ::after {
      content: '|';
      @apply inline-block pl-2;
    }

    &:last-child {
      ::after {
        @apply hidden;
      }
    }

    a {
      @apply text-gray-800 dark:text-white;

      &:hover {
        @apply underline;
      }
    }
  }
}

:deep(.footer-ctn) {
  img {
    @apply mx-auto mb-3;
  }
}
</style>
