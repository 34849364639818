<template>
  <div class="p-2 w-full mx-auto">
    <div class="animate-pulse">
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineSkeleton'
};
</script>

<style scoped></style>
