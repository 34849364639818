<template>
  <modal :maxWidth="state.maxWidth" :show="state.show" @close="closeModal()">
    <template #title>
      <div class="flex items-center justify-between">
        <span>{{ state.title }}</span>
        <span class="text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer"
              @click="closeModal()">
          <icon name="close"></icon>
        </span>
      </div>
    </template>
    <template #content>
      <form @submit.prevent="submit" v-if="!buyResponse">
        <div class="mb-2">
          <div class="flex items-center justify-between mb-1">
            <label>{{ translate('markets.wallet.balance.buy.enter_amount') }} EUR</label>
            <div class="text-blue-500 text-sm">{{ showError(errorBag, 'amount') }}</div>
          </div>
          <input v-model="form.amount" autofocus class="form-input w-full rounded" type="text" />
        </div>
        <div class="mt-4">
          <j-button :disabled="isProcessing" size="w-full">
            <div v-if="isProcessing" class="mr-3">
              <icon :name="'spinner'" classes="w-4 h-4 text-white"></icon>
            </div>
            <span>{{ translate('markets.wallet.balance.buy') }}</span>
          </j-button>
        </div>
      </form>
      <div v-else v-html="buyResponse"></div>
    </template>
  </modal>
</template>

<script setup>
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import { computed, inject, onBeforeMount, reactive, ref } from 'vue';
import validator from '@/validator';
import JButton from '@/templates/Button.vue';
import { useStore } from 'vuex';

const url = process.env.VUE_APP_API_URL;
const store = useStore();

// Providers
const translate = inject('translate');
const http = inject('http');
const emitter = inject('emitter');

const user = computed(() => store.state.user);

const isProcessing = ref(false);
// State
const state = reactive({
  title: '',
  show: false,
  maxWidth: 'xl',
  closeable: true
});
const record = ref(null);
const form = reactive({
  amount: ''
});
const buyResponse = ref(null);

const errorBag = ref({});
const rules = reactive({
  amount: ['required', 'regex:/^(\\d+)?([.]?\\d{1,8})?/']
});

const {
  validate,
  showError
} = validator();

const submit = () => {
  errorBag.value = validate(form, rules);
  if (Object.keys(errorBag.value).length === 0) {
    isProcessing.value = true;
    const params = {
      Call: 'CreateTransaction',
      MerchantID: user.value.ID,
      APIKey: user.value.APIKey,
      AltCoinID: record.value.id,
      Amount: form.amount,
      output: 'json'
    };
    http.get(`${url}/v2REAPI`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params
    }).then(response => {
      isProcessing.value = false;
      const matches = response.data.match(/href="([^'"]+)/g);
      if (matches.length > 0) {
        form.amount = '';
        errorBag.value = {};
      }
      buyResponse.value = response.data;
    }).catch(error => {
      isProcessing.value = false;
      console.log(error);
    });
  }
};

const closeModal = () => {
  state.show = false;
};

onBeforeMount(() => {
  emitter.on('buyModal', (args) => {
    state.title = args.title;
    record.value = args.balance;
    // Show modal
    state.show = true;
  });
});

</script>

<style scoped lang="scss">
::v-deep(a) {
    color: #3b82f6 !important;

    &:hover {
      @apply underline;
    }
}
</style>
