<template>
  <div class="card">
    <div class="flex items-center justify-between">
      <ul
        :class="`tab-set flex flex-col md:flex-row w-full md:w-auto flex-wrap bg-blue-700 text-gray-100 rounded-t ${full}`">
        <template v-for="(tab, index) in tabs" :key="index">
          <li :class="`${full} relative inline-flex items-center`">
            <a :class="`tab w-full ${tab.isActive ? 'active bg-blue-500' : ''}`" href="javascript:void(0)"
              @click="selectTab(tab)">
              <span :class="{ 'bg-blue-500': tab.isActive }" v-html="tab.name"></span>
            </a>
            <span @click="selectTab(tab)" class="cursor-pointer">
              <Icon name="click" v-if="!tab.isActive && isClickIcon" classes="w-5 h-5 mr-4">
              </Icon>
            </span>
          </li>
        </template>
      </ul>
      <span class="text-gray-700 dark:text-gray-400 mx-4" v-if="action" @click="isExpanded = !isExpanded">
        <icon v-if="!isExpanded" name="expand" classes="w-5 h-5"></icon>
        <icon v-if="isExpanded" name="collapse" classes="w-5 h-5"></icon>
      </span>
    </div>
    <hr v-if="isExpanded" class="border-gray-300 dark:border-gray-600" />
    <div class="body" v-show="isExpanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { onMounted, provide, reactive, toRefs } from 'vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'TabSet',
  components: { Icon },
  props: {
    full: {
      type: String,
      default: 'flex-1'
    },
    action: {
      type: Boolean,
      default: false
    },
    active: {
      type: String,
      default: null
    },
    isClickIcon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const state = reactive({
      activeTab: '',
      tabs: [],
      isExpanded: true
    });
    provide('tabsProvider', state);
    provide('addTab', (tab) => {
      state.tabs.push(tab);
    });
    provide('updateTab', (tab, index) => {
      state.tabs.splice(index, 1, tab);
    });
    const selectTab = (tab) => {
      const selectedTab = findTabBySlug(tab.slug);
      if (!selectedTab) {
        return;
      }
      state.tabs.forEach(tab => {
        tab.isActive = (tab.slug === selectedTab.slug);
      });
      state.activeTab = selectedTab.slug;

      emit('changed', { tab: selectedTab });
    };

    const findTabBySlug = (slug) => {
      return state.tabs.find(tab => tab.slug === slug);
    };

    onMounted(() => {
      if (!state.tabs.length) {
        return;
      }
      let selectedTab = state.tabs[0];
      if (props.active) {
        selectedTab = findTabBySlug(props.active);
      }
      state.tabs.forEach(tab => {
        if (tab.selected) {
          selectedTab = tab;
        }
      });
      selectTab(selectedTab);
    });

    return {
      ...toRefs(state),
      selectTab,
      findTabBySlug
    };
  }
};
</script>
