export const posRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: 'landing' */ '@/views/PosLanding.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/:id',
    name: 'shop-detail',
    component: () => import(/* webpackChunkName: 'shop-detail' */ '@/views/Shop.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/:id/categories/:categoryId',
    name: 'shop-detail-category',
    component: () => import(/* webpackChunkName: 'shop-detail-category' */ '@/views/Shop.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/:shopId/products/:id',
    name: 'product-detail',
    component: () => import(/* webpackChunkName: 'product-detail' */ '@/views/ProductDetail.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: 'cart' */ '@/views/Cart.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/Checkout.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shop/invoice/:confirmCode',
    name: 'shop-invoice',
    component: () => import(/* webpackChunkName: 'shop-invoice' */ '@/views/ShopInvoice.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: 'privacy-policy' */ '@/views/pos/static/PrivacyPolicy.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: () => import(/* webpackChunkName: 'terms' */ '@/views/pos/static/Terms.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/refund-return',
    name: 'refund-policy',
    component: () => import(/* webpackChunkName: 'refund-policy' */ '@/views/pos/static/RefundPolicy.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/shipping-policy',
    name: 'shipping-policy',
    component: () => import(/* webpackChunkName: 'shipping-policy' */ '@/views/pos/static/ShippingPolicy.vue'),
    meta: {
      title: 'Posercart.com'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];
