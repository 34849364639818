<template>
  <div>
    <TabSet full="">
      <template v-for="(m, index) in baseMarkets" :key="index">
        <Tab :name="m.name" :slug="m.short">
          <MarketMyHistory :refresh="props.refresh" :market="m.short"></MarketMyHistory>
        </Tab>
      </template>
    </TabSet>
  </div>
</template>

<script setup>
import MarketMyHistory from './MarketMyHistory.vue';
import TabSet from '@/components/common/TabSet.vue';
import Tab from '@/components/common/Tab.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  refresh: {
    type: Number,
    default: null
  }
});

const store = useStore();

const baseMarkets = computed(() => {
  return store.state.baseMarkets.map(m => {
    return {
      ...m,
      short: marketShortName(m.name.toLowerCase())
    };
  });
});

const marketShortName = (name) => {
  const names = {
    btc: 'btc', doge: 'dog', eurx: 'erx', ltc: 'ltc'
  };
  return names[name] || 'btc';
};

</script>

<style scoped></style>
