<template>
  <div>
    <div
      class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
      <table class="mb-2 table table-sm select-none border-t border-gray-200 dark:border-gray-700">
        <thead>
          <tr>
            <th v-if="all">Market</th>
            <th>{{ translate('markets.wallet.open_orders.date') }}</th>
            <th v-if="all">{{ translate('markets.wallet.open_orders.coin') }}</th>
            <th>{{ translate('markets.wallet.open_orders.type') }}</th>
            <th>{{ translate('markets.wallet.open_orders.price') }}</th>
            <th>{{ translate('markets.wallet.open_orders.amount') }}</th>
            <th>{{ translate('markets.wallet.open_orders.total') }}</th>
            <th>{{ translate('markets.wallet.open_orders.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!isLoading">
            <template v-if="orders.length">
              <tr v-for="order in orders" :key="order.id" @click="populateForm(order)">
                <td v-if="all">{{ order.Market }}</td>
                <td>{{ order.CreatedOn }}</td>
                <td v-if="all">{{ order.LongName }}</td>
                <td class="uppercase"
                  :class="`${order.TradeFlexTypeID === 1 ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'}`">
                  {{ order.TradeFlexTypeID === 1 ? translate('markets.wallet.open_orders.buy') :
              translate('markets.wallet.open_orders.sell') }}
                </td>
                <td>{{ toFixed(order.Price) }}</td>
                <td>{{ toFixed(order.Amount) }}</td>
                <td>{{ toFixed(order.Total) }}</td>
                <td>
                  <a class="cursor-pointer text-yellow-500" @click="cancelOrder(order)">
                    {{ translate('markets.wallet.open_orders.cancel_order') }}
                  </a>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td :colspan="`${all ? 8 : 6}`">{{ translate('markets.wallet.open_orders.empty') }}</td>
              </tr>
            </template>
          </template>

          <template v-else>
            <tr v-for="i in 5" :key="i">
              <td :colspan="`${all ? 8 : 6}`">
                <line-skeleton></line-skeleton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <pagination :meta="meta" @page="updatePage"></pagination>
  </div>
</template>

<script setup>
import Pagination from '@/components/common/Pagination.vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import LineSkeleton from '@/components/LineSkeleton.vue';
import findIndex from 'lodash/findIndex';
import compositionUtils from '@/compositionUtils';

const props = defineProps({
  refresh: {
    type: Number,
    default: null
  },
  wallId: {
    type: Number,
    default: null
  },
  all: {
    type: Boolean,
    default: true
  },
  limit: {
    type: Number,
    default: 20
  },
  tab: {
    type: String,
    default: 'balance'
  }
});

const emit = defineEmits([
  'populateForm'
]);

const store = useStore();

const swal = inject('swal');
const translate = inject('translate');
const toast = inject('toast');
const http = inject('http');

const { baseMarketName, toFixed } = compositionUtils();

const isLoading = ref(false);
const myOrders = ref([]);
const user = computed(() => store.state.user);
const market = computed(() => store.state.market);
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);

const orders = computed(() => {
  return myOrders.value.slice(
    meta.value.offset,
    meta.value.currentPage * meta.value.limit
  );
});

// eslint-disable-next-line vue/no-setup-props-destructure
const meta = ref({
  currentPage: 1,
  limit: props.limit,
  offset: 0,
  totalPages: 0
});
const canceledId = ref(null);

watch(() => props.refresh, (val) => {
  if (val && (props.tab === 'open-orders' || props.tab === 'my-orders')) {
    if (!props.wallId || props.wallId === canceledId.value) {
      fetchOpenOrders(false);
    } else {
      canceledId.value = props.wallId;
      const index = findIndex(myOrders.value, { ID: canceledId.value });
      if (index !== -1) {
        myOrders.value.splice(index, 1);
      }
    }
  }
});

const updatePage = (page) => {
  meta.value = {
    ...meta.value,
    currentPage: page,
    offset: (page - 1) * meta.value.limit
  };
};

const fetchOpenOrders = (isLoader = true) => {
  if (isLoader) {
    isLoading.value = true;
  }
  if (props.all) {
    Promise.all([
      http.get('https://artemis.cointopay.com/trading/', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          Call: 'OpenOrders',
          Output: 'json',
          MerchantID: user.value?.ID || 1,
          APIKey: user.value?.APIKey || '_',
          BaseMarket: 'btc'
        }
      }),
      http.get('https://artemis.cointopay.com/trading/', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          Call: 'OpenOrders',
          Output: 'json',
          MerchantID: user.value?.ID || 1,
          APIKey: user.value?.APIKey || '_',
          BaseMarket: 'ltc'
        }
      }),
      http.get('https://artemis.cointopay.com/trading/', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          Call: 'OpenOrders',
          Output: 'json',
          MerchantID: user.value?.ID || 1,
          APIKey: user.value?.APIKey || '_',
          BaseMarket: 'dog'
        }
      }),
      http.get('https://artemis.cointopay.com/trading/', {
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          Call: 'OpenOrders',
          Output: 'json',
          MerchantID: user.value?.ID || 1,
          APIKey: user.value?.APIKey || '_',
          BaseMarket: 'erx'
        }
      })
    ]).then(response => {
      isLoading.value = false;
      let data = [];
      const mList = ['BTC', 'LTC', 'DOGE', 'EURx'];
      response.forEach((res, index) => {
        let records = res.data.data;
        if (records.length > 0) {
          records = records.map(r => {
            return {
              ...r,
              Market: mList[index]
            };
          });
        }
        data = data.concat(records);
      });
      myOrders.value = data;
      meta.value = {
        ...meta.value,
        totalPages: Math.ceil(myOrders.value.length / meta.value.limit)
      };
    }).catch(error => {
      isLoading.value = false;
      console.log(error);
    });
  } else {
    const params = {
      Call: 'ShowMyWalls',
      Output: 'json',
      MerchantID: user.value?.ID || 1,
      APIKey: user.value?.APIKey || '_',
      AltCoinID: market.value.AltCoinID,
      BaseMarket: baseMarketName(baseMarket.value.name)
    };
    const endPoint = 'https://artemis.cointopay.com/trading/';

    http.get(endPoint, {
      headers: {
        'Content-Type': 'application/json'
      },
      params
    }).then(response => {
      isLoading.value = false;
      myOrders.value = response.data.data;
      meta.value = {
        ...meta.value,
        totalPages: Math.ceil(myOrders.value.length / meta.value.limit)
      };
    }).catch(error => {
      isLoading.value = false;
      if (error.data && error.data?.reason) {
        toast.error(error.data.reason);
      } else {
        console.log(error);
      }
    });
  }
};

const cancelOrder = (order) => {
  swal.fire({
    title: translate('markets.wallet.open_orders.cancel_order'),
    html: `
      <p>${translate('markets.wallet.open_orders.are_you_sure')}</p>
      <p>${translate('markets.wallet.open_orders.type')} ${order.TradeFlexTypeID === 1 ? translate('markets.wallet.open_orders.buy') : translate('markets.wallet.open_orders.sell')}</p>
      <p>${translate('markets.wallet.open_orders.price')}: ${toFixed(order.Price)}</p>
      <p>${translate('markets.wallet.open_orders.amount')}: ${toFixed(order.Amount)}</p>
    `,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    confirmButtonText: translate('markets.wallet.open_orders.yes'),
    cancelButtonText: translate('markets.wallet.open_orders.no'),
    focusCancel: true,
    customClass: {
      container: 'swal2-tailwind'
    }
  }).then(({ value }) => {
    if (value && value === true) {
      const params = {
        Call: 'CancelTrade',
        TradeID: order.ID,
        Output: 'json',
        MerchantID: user.value.ID,
        APIKey: user.value.APIKey,
        BaseMarket: props.all
          ? baseMarketName(order.Market)
          : baseMarketName(baseMarket.value.name)
      };
      const endPoint = 'https://artemis.cointopay.com/trading/';
      http.get(endPoint, {
        headers: {
          'Content-Type': 'application/json'
        },
        params
      }).then(response => {
        if (response.data.status === 'success') {
          // Remove cancelled order
          myOrders.value.splice(myOrders.value.indexOf(order), 1);
          meta.value = {
            ...meta.value,
            totalPages: Math.ceil(myOrders.value.length / meta.value.limit)
          };
          if (meta.value.currentPage > meta.value.totalPages) {
            meta.value = {
              ...meta.value,
              currentPage: meta.value.currentPage > 1 ? meta.value.currentPage - 1 : meta.value.currentPage
            };
          }
          toast.success('Trade cancelled');
        }
      }).catch(error => {
        toast.error(error.reason);
        if (error.data && error.data?.reason) {
          toast.error(error.data.reason);
        } else {
          console.log(error);
        }
      });
    }
  });
};

const populateForm = (order) => {
  emit('populateForm', order);
};

onBeforeMount(() => {
  fetchOpenOrders();
});
</script>

<style scoped></style>
