<template>
  <modal :maxWidth="state.maxWidth" :show="state.show" @close="closeModal()">
    <template #title>
      <div class="flex items-center justify-between">
        <span></span>
        <span class="text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 cursor-pointer"
          @click="closeModal()">
          <icon name="close"></icon>
        </span>
      </div>
    </template>
    <template #content>
      <div class="px-6 w-full text-center">
        <img class="mx-auto" :src="record.QRCodeURL" alt="" />
        <p class="mt-2" style="word-break: break-all;">
          {{ record.coinAddress }}
        </p>
        <p class="font-bold text-red-500 text-lg">{{ timer }}</p>
        <p>Deposit minimum amount of: {{ numToPlain(record.Amount) + ' ' + record.CoinName }}</p>
        <p class="w-full" v-if="record.Tag && record.Tag !== ''">
          Memo/Tag: {{ record.Tag }}
        </p>
        <a target="_blank" :href="record.RedirectURL" class="btn btn-success mt-4">
          {{ translate('invoice.invoice') }}
        </a>
      </div>
    </template>
  </modal>
</template>

<script setup>
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import { inject, onBeforeMount, reactive, ref } from 'vue';
import compositionUtils from '@/compositionUtils';

// Providers
const translate = inject('translate');
const emitter = inject('emitter');

const { numToPlain } = compositionUtils();

// State
const state = reactive({
  show: false,
  maxWidth: '2xl',
  closeable: true
});
const timer = ref('');
const interval = ref(null);
const record = ref(null);

const closeModal = () => {
  state.show = false;
  if (interval.value) {
    clearInterval(interval.value);
  }
};

const startTimer = (time) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + time);
  interval.value = setInterval(() => {
    const now = new Date().getTime();
    const diff = date.getTime() - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    timer.value = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    if (diff < 0) {
      clearInterval(interval.value);
      timer.value = 'Expired';
    }
  }, 1000);
};

onBeforeMount(() => {
  emitter.on('depositResponseModal', (args) => {
    record.value = args.record;
    startTimer(record.value.ExpiryTime);
    // Show modal
    state.show = true;
  });
});

</script>

<style scoped></style>
