<template>
  <div>
    <div class="flex items-end justify-end mb-3">
      <select class="form-select w-64 mr-3" v-model="status">
        <option value="">All</option>
        <option value="1">Open</option>
        <option value="2">Fulfilled</option>
        <option value="3">Cancelled</option>
      </select>
    </div>
    <div
      class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
      <table class="mb-2 table table-sm select-none border-t border-gray-200 dark:border-gray-700">
        <thead>
          <tr>
            <th>ID</th>
            <th>Market</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.coin') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.type') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.status') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.price') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.exchanged_price') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.amount') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.exchanged_amount') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.total') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.created_on') }}</th>
            <th class="whitespace-nowrap">{{ translate('markets.wallet.open_orders.confirmed_on') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!isLoading">
            <template v-if="myHistory.length">
              <tr v-for="order in myHistory" :key="order.ID">
                <td>{{ order.ID }}</td>
                <td>{{ tableToMarket(order.Tbl) }}</td>
                <td>{{ `${order.CoinName} (${order.LongName})` }}</td>
                <td class="uppercase"
                  :class="`${order.TradeFlexTypeID === 1 ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'}`">
                  {{ order.TradeFlexTypeID === 1 ? translate('markets.wallet.open_orders.buy') :
        translate('markets.wallet.open_orders.sell') }}
                </td>
                <td class="uppercase" :class="formatClass(order.TradeFlexStatusID)">
                  {{ tradeFlexStatus(order.TradeFlexStatusID) }}
                </td>
                <td>{{ toFixed(order.Price) }}</td>
                <td>{{ toFixed(order.ExchangedPrice) }}</td>
                <td>{{ toFixed(order.Amount) }}</td>
                <td>{{ toFixed(order.ExchangedAmount) }}</td>
                <td>{{ toFixed(order.Price * order.Amount) }}</td>
                <td>{{ order.CreatedOn }}</td>
                <td><span v-if="order.TradeFlexStatusID !== 1">{{ order.ConfirmedOn }}</span></td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td colspan="14">{{ translate('markets.wallet.open_orders.empty') }}</td>
              </tr>
            </template>
          </template>

          <template v-else>
            <tr v-for="i in 5" :key="i">
              <td colspan="14">
                <line-skeleton></line-skeleton>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <pagination :meta="meta" @page="updatePage"></pagination>
  </div>
</template>

<script setup>
import Pagination from '@/components/common/Pagination.vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import LineSkeleton from '@/components/LineSkeleton.vue';
import findIndex from 'lodash/findIndex';
import compositionUtils from '@/compositionUtils';

const props = defineProps({
  refresh: {
    type: Number,
    default: null
  },
  market: {
    type: String,
    default: 'btc'
  }
});

const store = useStore();

const translate = inject('translate');
const http = inject('http');

const { toFixed } = compositionUtils();

const status = ref('');

const isLoading = ref(false);
const myHistory = ref([]);
const user = computed(() => store.state.user);

const meta = ref({
  currentPage: 1,
  limit: 20,
  offset: 0,
  totalPages: 0,
  total: 0
});
const canceledId = ref(null);

watch(() => props.refresh, (val) => {
  if (val) {
    if (!props.wallId || props.wallId === canceledId.value) {
      fetchMyHistory(false);
    } else {
      canceledId.value = props.wallId;
      const index = findIndex(myHistory.value, { ID: canceledId.value });
      if (index !== -1) {
        myHistory.value.splice(index, 1);
      }
    }
  }
});

watch(() => props.market, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    fetchMyHistory(true, 1);
  }
});

watch(() => status.value, (val) => {
  fetchMyHistory(true, 1);
});

const updatePage = (page) => {
  fetchMyHistory(true, page);
};

const formatClass = (val) => {
  switch (val) {
    case '1':
    case 1:
      return 'text-yellow-500 dark:text-yellow-500';
    case '2':
    case 2:
      return 'text-green-500 dark:text-green-500';
    case '3':
    case 3:
      return 'text-red-500 dark:text-red-500';
  }
};

const tableToMarket = (tbl) => {
  const tbls = {
    TradeFlex: 'BTC', TradeFlexDoge: 'DOGE', TradeFlexEurx: 'EURx', TradeFlexLTC: 'LTC'
  };
  return tbls[tbl] || 'BTC';
};

const tradeFlexStatus = (val) => {
  let label = '';
  switch (val) {
    case 1:
      label = 'Open';
      break;
    case 2:
      label = 'Fulfilled';
      break;
    case 3:
      label = 'Cancelled';
      break;
  }
  return label;
};

const fetchMyHistory = (isLoader = true, page = 1) => {
  if (isLoader) {
    isLoading.value = true;
  }
  http.get('https://artemis.cointopay.com/trading/', {
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      Call: 'MyHistory',
      Output: 'json',
      MerchantID: user.value?.ID,
      APIKey: user.value?.APIKey,
      BaseMarket: props.market,
      page,
      status: status.value
    }
  }).then(response => {
    isLoading.value = false;
    const data = response.data.data;
    myHistory.value = data.records;
    meta.value = {
      ...meta.value,
      currentPage: data.meta.page,
      limit: data.meta.limit,
      offset: data.meta.offset,
      totalPages: Math.ceil(data.meta.total / data.meta.limit),
      total: data.meta.total
    };
  }).catch(error => {
    isLoading.value = false;
    console.log(error);
  });
};

onBeforeMount(() => {
  fetchMyHistory();
});
</script>

<style scoped></style>
