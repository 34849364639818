<template>
  <div class="flex flex-col md:flex-row items-center justify-between mb-3">
    <div class="font-semibold mb-2 md:mb-0">
      <span v-if="total">{{ total.fiatvalue + ' ' + total.fiat }}</span>
    </div>
    <div class="relative">
      <icon classes="absolute w-5 h-5 top-3 left-3 dark:text-gray-300" name="search"></icon>
      <input v-model="keyword" aria-label="Search" class="form-input px-10 w-full" type="text" />
      <span v-show="keyword !== ''" class="absolute top-3 right-3" @click="keyword = ''">
        <icon classes="w-5 h-5 dark:text-gray-300" name="close"></icon>
      </span>
    </div>
  </div>
  <div
    class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
    <table class="mb-2 table table-sm select-none border-t border-gray-200 dark:border-gray-700" v-if="!isLoading">
      <thead>
        <tr>
          <th class="whitespace-nowrap">{{ translate('markets.wallet.balance.coin_name') }}</th>
          <th class="whitespace-nowrap">{{ translate('markets.wallet.balance.code') }}</th>
          <th class="whitespace-nowrap">{{ translate('markets.wallet.balance.balance') }}</th>
          <th class="whitespace-nowrap">{{ translate('markets.wallet.balance.fiat_value') }}</th>
          <th>Markets</th>
          <th class="whitespace-nowrap">{{ translate('markets.wallet.balance.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="balance in myBalanceList" :key="balance.id">
          <td>
            <a class="cursor-pointer flex items-center" @click="loadMarket('BTC', balance)">
              <img class="w-6 mr-2" :src="generateImage(balance.name)" alt="" />
              {{ balance.name }}
            </a>
          </td>
          <td @click="loadMarket('BTC', balance)">{{ balance.longname }}</td>
          <td @click="loadMarket('BTC', balance)">{{ toFixed(balance.balance) }}</td>
          <td @click="loadMarket('BTC', balance)">{{ numToPlain(balance?.fiatvalue, 2) }} {{ balance?.fiat }}</td>
          <td>
            <template v-if="balance.id !== 1">
              <a class="cursor-pointer" @click="loadMarket('BTC', balance)">BTC</a>
              <span>&nbsp;|&nbsp;</span>
            </template>
            <template v-if="balance.id !== 1 && balance.id !== 9">
              <a class="cursor-pointer" @click="loadMarket('DOG', balance)">DOGE</a>
              <span>&nbsp;|&nbsp;</span>
            </template>
            <template v-if="balance.id !== 1 && balance.id !== 726">
              <a class="cursor-pointer" @click="loadMarket('ERX', balance)">EURx</a>
              <span v-if="balance.id !== 2">&nbsp;|&nbsp;</span>
            </template>
            <template v-if="balance.id !== 1 && balance.id !== 2">
              <a class="cursor-pointer" @click="loadMarket('LTC', balance)">LTC</a>
            </template>
          </td>
          <td>
            <div>
              <span v-if="activeCoin && activeCoin.id === balance.id && isActive">
                <Icon name="spinner" classes="w-4 h-4" />
              </span>
              <template v-if="balance.deposit === '0'">
                <a class="cursor-pointer" v-if="isReceiveEnabled"
                  :class="`${activeCoin && activeCoin.id === balance.id && isActive ? 'hidden' : ''}`"
                  @click="deposit(balance)">{{ translate('markets.wallet.balance.deposit') }}</a>
                <a v-else class="cursor-not-allowed text-gray-500">{{ translate('markets.wallet.balance.deposit')
                  }}</a>
                <span>&nbsp;|&nbsp;</span>
              </template>
              <a class="cursor-pointer" @click="withdraw(balance)" v-if="balance.payout === '0'">
                {{ translate('markets.wallet.balance.withdraw') }}</a>
              <span v-if="balance.payout === '0'">&nbsp;|&nbsp;</span>
              <a v-if="isBuyEnabled" class="cursor-pointer" @click="launchBuyModal(balance)">
                {{ translate('markets.wallet.balance.buy') }}</a>
              <a v-else class="cursor-not-allowed text-gray-500">{{ translate('markets.wallet.balance.buy') }}</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <markets-skeleton :counter="4" v-else></markets-skeleton>
  </div>
  <!-- Deposit Response Modal -->
  <deposit-response-modal></deposit-response-modal>
  <!-- Buy Modal -->
  <buy-modal></buy-modal>
</template>

<script setup>
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import Icon from '@/components/Icon.vue';
import MarketsSkeleton from '@/components/MarketsSkeleton.vue';
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import compositionUtils from '@/compositionUtils';
import { useRouter } from 'vue-router';
import { FETCH_ACCOUNT_INFO, SET_WITHDRAW_BALANCE } from '@/store/keys';
import DepositResponseModal from '@/components/exchange-wallet/modals/DepositResponseModal.vue';
import BuyModal from '@/components/exchange-wallet/modals/BuyModal.vue';
import orderBy from 'lodash/orderBy';

const props = defineProps([
  'refresh',
  'tab'
]);

const store = useStore();
const router = useRouter();

const translate = inject('translate');
const emitter = inject('emitter');
const http = inject('http');
const toast = inject('toast');

const url = process.env.VUE_APP_API_URL;

const user = computed(() => store.state.user);
const myBalanceList = computed(() => {
  if (keyword.value) {
    return myBalance.value.filter(b => {
      return (
        includes(b.name.toLowerCase(), keyword.value.toLowerCase()) ||
        includes(b.longname.toLowerCase(), keyword.value.toLowerCase())
      );
    });
  }
  return myBalance.value;
});

const isLoading = ref(false);
const total = ref(null);
const myBalance = ref([]);
const isActive = ref(false);
const activeCoin = ref(null);
const isBuyEnabled = ref(true);
const isReceiveEnabled = ref(true);
const accountInfo = ref(null);
const depositResponse = ref(null);
const keyword = ref('');

const { numToPlain, toFixed } = compositionUtils();

const emit = defineEmits(['depositResponse']);

watch(() => props.refresh, (val) => {
  if (val && props.tab === 'balance') {
    fetchBalanceList(false);
  }
});

const fetchBalanceList = (isLoader = true) => {
  if (!user.value) {
    return;
  }
  if (isLoader) {
    isLoading.value = true;
  }
  const params = {
    Call: 'BalanceOverview',
    output: 'json',
    MerchantID: user.value.ID,
    APIKey: user.value.APIKey
  };
  http.get(`${url}/v2REAPI`, {
    headers: {
      'Content-Type': 'application/json'
    },
    params
  }).then(response => {
    isLoading.value = false;
    let data = response.data;
    if (data.length) {
      data = data.filter(d => d.securecloud !== '2');
    }
    if (data === 'authentication failed') {
      toast.error('Authentication failed');
      emitter.emit('loginModal', {});
    } else {
      let sortKey = 'name';
      let sortReverse = false;
      data.map(balance => {
        if (balance.balance > 0) {
          sortKey = 'balance';
          sortReverse = true;
        }
        balance.balance = numToPlain(balance.balance);
        if (balance.id === 0) {
          total.value = balance;
        }
        return balance;
      });
      remove(data, { id: 0 });
      // Sort data
      data = orderBy(data, [sortKey], [sortReverse ? 'desc' : 'asc']);
      myBalance.value = data;
      if (data.length > 0) {
        total.value.fiat = data[0].fiat;
      }
    }
  }).catch(error => {
    isLoading.value = false;
    console.log(error);
  });
};

const generateImage = (name) => {
  return (
    'https://s3-eu-west-1.amazonaws.com/cointopay/img/' +
    name.toLowerCase() +
    '_dash2.png'
  );
};

const loadMarket = (market, balance) => {
  router.push(`/tradeview/${market}_${balance.longname}`);
};

const deposit = (balance) => {
  if (!isActive.value) {
    if (balance) {
      isActive.value = true;
      activeCoin.value = balance;
      const params = {
        Checkout: true,
        MerchantID: user.value.ID,
        SecurityCode: user.value.SecurityCode,
        Amount: numToPlain(0.00000001),
        AltCoinID: balance.id,
        CustomerReferenceNr: balance.name + '_' + user.value.Username,
        output: 'json'
      };
      http.get(`${url}/MerchantAPI`, {
        headers: {
          'Content-Type': 'application/json'
        },
        params
      }).then(response => {
        depositResponse.value = response.data;
        emitter.emit('depositResponseModal', {
          record: depositResponse.value
        });
        emit('depositResponse', depositResponse.value);
        isActive.value = false;
        activeCoin.value = null;
      }).catch(error => {
        isActive.value = false;
        activeCoin.value = null;
        if (error.status === 400 && error.data) {
          toast.error(error.data);
        } else {
          console.log(error);
        }
      });
    }
  }
};

const withdraw = (balance) => {
  if (balance.balance > 0) {
    store.commit(SET_WITHDRAW_BALANCE, balance);
    router.push('/wallet/withdraw');
  } else {
    toast.error(translate('markets.trading.not_enough_balance'));
  }
};

const launchBuyModal = (balance) => {
  emitter.emit('buyModal', {
    title: `${translate('markets.form.buy')} ${balance.name}`,
    balance
  });
};

onBeforeMount(() => {
  fetchBalanceList();
  store.dispatch(FETCH_ACCOUNT_INFO).then(response => {
    if (response.message.toLowerCase() === 'no merchant session') {
      // router.push('/markets')
      toast.warning('Session expired, Please login again.');
    } else {
      accountInfo.value = response;
      if (Object.keys(accountInfo.value).length > 0 && accountInfo.value.FinalVerification === '0') {
        isBuyEnabled.value = false;
        isReceiveEnabled.value = false;
      }
    }
  });
});

</script>

<style scoped></style>
