<template>
  <div class="card">
    <div class="title md:flex items-center justify-between">
      <h5 class="font-semibold uppercase">{{ translate('markets.form.buy') }} {{ market.ShortName }} {{
        translate('markets.form.with') }} {{ baseMarket.name }}</h5>
      <span class="text-sm cursor-pointer text-right" @click="populateBalance">
        <span class="uppercase">{{ baseMarket.name }}</span>
        {{ translate('markets.form.balance') }}:
        {{ numToPlain(balance) }}</span>
    </div>
    <hr class="border-gray-300 dark:border-gray-600" />
    <div class="body">
      <form @submit.prevent="submit">
        <div class="mb-2 grid grid-cols-3 gap-1 md:gap-4">
          <label class="col-span-3 md:col-span-1">
            {{ translate('markets.form.amount') }}
            <span class="ml-1 text-red-500">*</span>
          </label>
          <div class="col-span-3 md:col-span-2">
            <div class="relative">
              <input type="text" class="form-input w-full" v-model="form.amount" @keyup="calculate('amount', $event)"
                @blur="calculate('amount', $event)" :placeholder="translate('markets.form.amount')" />
              <span class="absolute uppercase text-sm right-3 top-3">{{ market.ShortName }}</span>
            </div>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'amount') }}</div>
          </div>
        </div>
        <div class="mb-2 grid grid-cols-3 gap-1 md:gap-4">
          <label class="col-span-3 md:col-span-1">
            {{ translate('markets.form.price') }}
            <span class="ml-1 text-red-500">*</span>
          </label>
          <div class="col-span-3 md:col-span-2">
            <div class="relative">
              <input type="text" class="form-input w-full" v-model="form.price" @keyup="calculate('price', $event)"
                @blur="calculate('price', $event)" :placeholder="translate('markets.form.price')" />
              <span class="absolute uppercase text-sm right-3 top-3">{{ baseMarket.name }}</span>
            </div>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'price') }}</div>
          </div>
        </div>
        <div class="mb-2 grid grid-cols-3 gap-1 md:gap-4">
          <label class="col-span-3 md:col-span-1">
            {{ translate('markets.form.total') }}
            <span class="ml-1 text-red-500">*</span>
          </label>
          <div class="col-span-3 md:col-span-2">
            <div class="relative">
              <input type="text" class="form-input w-full" v-model="form.total" @keyup="calculate('total', $event)"
                @blur="calculate('total', $event)" :placeholder="translate('markets.form.total')" />
              <span class="absolute uppercase text-sm right-3 top-3">{{ baseMarket.name }}</span>
            </div>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'total') }}</div>
          </div>
        </div>
        <div class="mb-2 grid grid-cols-3 gap-1 md:gap-4">
          <div class="hidden md:inline-block md:col-span-1"></div>
          <div class="col-span-3 md:col-span-2">
            <button class="btn btn-success w-full" v-if="isAuthenticated" :disabled="isProcessing || !isValid">
              {{ translate('markets.form.buy') }}
            </button>
            <button type="button" class="btn btn-success w-full" @click="launchLoginModal()" v-else>
              {{ translate('login.button') }} / {{ translate('signup.button') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import validator from '@/validator';
import compositionUtils from '@/compositionUtils';

const props = defineProps({
  data: {
    type: Object,
    default: null
  },
  balance: {
    type: Number,
    default: 0
  },
  sellWallData: {
    type: Object,
    default: null
  }
});

const emit = defineEmits([
  'refresh'
]);

const store = useStore();
const http = inject('http');
const translate = inject('translate');
const toast = inject('toast');
const swal = inject('swal');

const form = reactive({
  amount: '',
  price: '',
  total: ''
});
const errorBag = ref({});
const rules = reactive({
  amount: ['required', 'regex:/^(\\d+)?([.]?\\d{1,8})?/'],
  price: ['required', 'regex:/^(\\d+)?([.]?\\d{1,8})?/'],
  total: ['required', 'regex:/^(\\d+)?([.]?\\d{1,8})?/']
});
const isProcessing = ref(false);

const isAuthenticated = computed(() => store.state.isLoggedIn);
const market = computed(() => store.state.market);
const user = computed(() => store.state.user);
const baseMarket = computed(() => store.state.baseMarkets.filter(b => b.isSelected)[0]);
const isValid = computed(() => Object.keys(validate(form, rules)).length === 0);

watch(() => props.data, (val) => {
  if (val) {
    Object.assign(form, {}, {
      amount: Number(val.Amount).toFixed(8) || form.amount,
      price: Number(val.Price).toFixed(8) || form.price,
      total: Number(val.Total).toFixed(8) || form.total
    });
    const key = val.Amount ? 'amount' : 'total';
    calculate(key);
  }
}, {
  deep: true
});

const { numToPlain, baseMarketName, toFixed } = compositionUtils();

const {
  validate,
  showError
} = validator();

const emitter = inject('emitter');
const launchLoginModal = () => {
  emitter.emit('loginModal', {});
};

const submit = () => {
  errorBag.value = validate(form, rules);
  if (Object.keys(errorBag.value).length === 0) {
    if (props.balance >= form.total) {
      swal.fire({
        title: '',
        html: `
          <p>Do you confirm?</p>
          <p>Amount: ${toFixed(form.amount)} ${market.value.ShortName}</p>
          <p>Price: ${toFixed(form.price)} ${baseMarket.value.name}</p>
          <p>Total: ${toFixed(form.total)} ${baseMarket.value.name}</p>
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: translate('markets.wallet.open_orders.yes'),
        cancelButtonText: translate('markets.wallet.open_orders.no'),
        focusCancel: true,
        customClass: {
          container: 'swal2-tailwind'
        }
      }).then(({ value }) => {
        if (value && value === true) {
          isProcessing.value = true;
          const params = {
            Call: 'SetBuy',
            Amount: form.amount,
            Price: form.price,
            AltCoinID: market.value.AltCoinID,
            Output: 'json',
            MerchantID: user.value.ID,
            APIKey: user.value.APIKey,
            BaseMarket: baseMarketName(baseMarket.value.name)
          };
          const endPoint = 'https://artemis.cointopay.com/trading/';
          http.get(endPoint, {
            headers: {
              'Content-Type': 'application/json'
            },
            params
          }).then(response => {
            isProcessing.value = false;
            if (response.data.result === 'failed') {
              toast.error(response.data.reason);
            } else {
              toast.success(translate('markets.trading.trade_posted'));
              emit('refresh');
            }
          }).catch(error => {
            isProcessing.value = false;
            if (error.data && error.data?.reason) {
              toast.error(error.data.reason);
            } else {
              console.log(error);
            }
          });
        }
      });
    } else {
      toast.error(translate('markets.trading.not_enough_balance'));
    }
  }
};

const isNotEmpty = (value, key, errors) => {
  return value !== null && value !== '' && !errors[key];
};

const calculate = (key, evt) => {
  if (evt && (evt.code === 'Backspace' || evt.code === 'Delete')) {
    if (form[key] === '') {
      if (key !== 'total') {
        form.total = '';
      }
      return;
    }
  }
  const errors = validate(form, rules);
  if (Object.keys(errors).length > 0 && errors[key] && errors[key] !== '') {
    return;
  }
  let amount = form.amount;
  const price = form.price;
  let total = form.total;

  if (key === 'amount' || key === 'price') {
    if (isNotEmpty(amount, 'amount', errors) && isNotEmpty(price, 'price', errors)) {
      total = amount * price;
      form.total = Number(total).toFixed(8);
    } else if (isNotEmpty(price, 'price', errors) && isNotEmpty(total, 'total', errors) && price > 0) {
      amount = total / price;
      form.amount = Number(amount).toFixed(8);
    }
  } else if (key === 'total') {
    if (isNotEmpty(total, 'total', errors) && isNotEmpty(price, 'price', errors) && price > 0) {
      amount = total / price;
      form.amount = Number(amount).toFixed(8);
    }
  }
};

const populateBalance = () => {
  form.total = props.balance;
  if (props.sellWallData && props.sellWallData.length > 0) {
    form.price = Number(props.sellWallData[0].Price).toFixed(8);
  }
  calculate('total');
};

</script>

<style scoped></style>
