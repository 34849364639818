<script setup>
import VueMultiselect from 'vue-multiselect';
import Icon from '@/components/Icon.vue';
import { computed, inject, onBeforeMount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import compositionUtils from '@/compositionUtils';
import findIndex from 'lodash/findIndex';

const http = inject('http');
const toast = inject('toast');

const store = useStore();

const apzTextBox = ref(null);
const messagesContainer = ref(null);
const isOpen = ref(false);
const ctrlDown = ref(false);
const canSend = ref(true);
const messages = ref([]);
const contacts = ref([]);
const selectedMerchant = ref(null);
const isShowNote = ref(true);
const merchant = ref({
  id: 21901,
  apiKey: 'QBNTYYXG6AOO9NNV2NUY3MEWLSBSOY8Z5Y--TMOQUII',
  name: 'ebrighter'
});
const targetMerchant = ref(merchant.value.name);

const { formatTime } = compositionUtils();

const user = computed(() => store.state.user);

watch(() => user.value, (val) => {
  if (val && Number(val.ID) === 1) {
    fetchContacts();
  } else {
    targetMerchant.value = merchant.value.name;
    loadChatHistory(targetMerchant.value);
  }
}, {
  deep: true
});

watch(() => selectedMerchant.value, (newVal) => {
  if (newVal && newVal.name) {
    targetMerchant.value = newVal.name;
    loadChatHistory(targetMerchant.value);
  }
});

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const placeCaretAtEnd = (el) => {
  if (
    typeof window.getSelection !== 'undefined' &&
    typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange();
    const textNode = document.createTextNode('\u00a0');
    range.selectNodeContents(el);
    range.collapse(false);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof (document.body).createTextRange !== 'undefined') {
    const textRange = (document.body).createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

const fetchContacts = () => {
  if (!user.value) {
    return;
  }
  const payload = {
    Call: 'CONTACTLIST',
    MerchantID: Number(user.value.ID) === 1 ? merchant.value.id : user.value.ID,
    APIKey: Number(user.value.ID) === 1 ? merchant.value.apiKey : user.value.APIKey,
    TargetMerchant: user.value.Username,
    output: 'json'
  };
  const url = process.env.VUE_APP_API_URL;
  http.get(`${url}/MarketAPI?MSGS`, {
    params: payload
  }).then(response => {
    let data = response.data.result;
    if (data !== 'null') {
      data = data.split(';').filter(Boolean);
      contacts.value = [];
      data.forEach(r => {
        r = r.split('::').filter(Boolean);
        if (r.length === 4) {
          const index = findIndex(contacts.value, { id: r[1] });
          if (index === -1) {
            contacts.value.push({
              id: r[1],
              name: r[0],
              count: r[3],
              label: `${r[0]} ${r[3] > 0 ? '●' : ''}`
            });
          }
        }
      });
      if (selectedMerchant.value === null) {
        selectedMerchant.value = contacts.value[0];
        targetMerchant.value = selectedMerchant.value.name;
      }
      loadChatHistory(targetMerchant.value);
    }
  }).catch(error => {
    console.log(error);
  });
};

const loadChatHistory = (targetMerchant = merchant.value.name) => {
  if (!user.value) {
    return;
  }
  const payload = {
    Call: 'HISTORY',
    MerchantID: Number(user.value.ID) === 1 ? merchant.value.id : user.value.ID,
    APIKey: Number(user.value.ID) === 1 ? merchant.value.apiKey : user.value.APIKey,
    TargetMerchant: targetMerchant,
    output: 'json'
  };
  const url = process.env.VUE_APP_API_URL;
  http.get(`${url}/MarketAPI?MSGS`, {
    params: payload
  }).then(response => {
    messages.value = response.data;
    scrollToBottom();
  }).catch(error => {
    console.log(error);
  });
};

const scrollTo = (element, to, duration) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 10;
  const currentTime = 0;
  animateScroll(
    element,
    currentTime,
    increment,
    start,
    change,
    duration
  );
};

const animateScroll = (element, currentTime, increment, start, change, duration) => {
  currentTime += increment;
  element.scrollTop = easeInOutQuad(
    currentTime,
    start,
    change,
    duration
  );
  if (currentTime < duration) {
    setTimeout(
      animateScroll(
        element,
        currentTime,
        increment,
        start,
        change,
        duration
      ),
      increment
    );
  }
};

const scrollToBottom = () => {
  setTimeout(() => {
    if (messagesContainer.value) {
      scrollTo(messagesContainer.value, messagesContainer.value.scrollHeight, 2000);
    }
  }, 10);
};

const toggleOpen = (open = null) => {
  isOpen.value = open !== null ? open : !isOpen.value;
  if (isOpen.value) {
    scrollToBottom();
  }
};

// Chat
const toggleCtrlStatus = (event) => {
  const txt = apzTextBox.value.textContent;
  if (event.which === 17) {
    ctrlDown.value = false;
  }
  if (txt !== '') {
    canSend.value = true;
  }
};

const submitOnEnter = (event) => {
  if (event.which === 17) {
    ctrlDown.value = true;
  }
  if (ctrlDown.value && event.which === 13) {
    event.preventDefault();
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement('br');
      const textNode = document.createTextNode('\u00a0'); // Passing
      range.deleteContents(); // required or not?
      range.insertNode(br);
      range.collapse(false);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
      return false;
    }
  } else if (event.which === 13) {
    event.preventDefault();
    let message = apzTextBox.value.textContent;
    if (message.startsWith('@')) {
      let parts = message.split('\u00a0');
      if (parts.length < 2) {
        parts = message.split(' ', 1);
      }
      message = message
        .replace(parts[0], '')
        .replace('\u00a0', '')
        .trim();
    }
    message = message.replaceAll('%C2%A0', ' ');
    if (!isEmpty(targetMerchant.value)) {
      canSend.value = false;
      sendMessage(message);
    }
  }
};

const send = () => {
  let message = apzTextBox.value.textContent;
  if (message.startsWith('@')) {
    let parts = message.split('\u00a0');
    if (parts.length < 2) {
      parts = message.split(' ', 1);
      message = message
        .replace(parts[0], '')
        .replace('\u00a0', '')
        .trim();
    }
    targetMerchant.value = parts[0].replace('@', '');
  }
  message = message.replaceAll('%C2%A0', ' ');
  canSend.value = false;
  sendMessage(message);
};

const playSound = () => {
  const audio = new Audio('/msg.mp3');
  audio.play().catch(_error => {
  });
};

const sendMessage = (message) => {
  message = message.trim();
  if (message === '') {
    toast.error('Please provide a message');
    return;
  }
  if (Number(user.value.ID) !== 1) {
    targetMerchant.value = merchant.value.name;
  }
  const payload = {
    Call: 'SEND',
    MerchantID: Number(user.value.ID) === 1 ? merchant.value.id : user.value.ID,
    APIKey: Number(user.value.ID) === 1 ? merchant.value.apiKey : user.value.APIKey,
    TargetMerchant: targetMerchant.value,
    TEXT: message.trim()
  };
  const url = process.env.VUE_APP_API_URL;
  const elmTxtArea = document.createElement('textarea');
  elmTxtArea.value = message;
  http.get(`${url}/MarketAPI?MSGS`, {
    params: payload
  }).then(response => {
    if (response) {
      const id = response.data.result.split(' ')[2];
      const msg = {
        ContactName: user.value.Username,
        CreatedOn: formatTime(new Date()),
        FROM: Number(user.value.ID) === 1 ? merchant.value.id : user.value.ID,
        ID: id,
        Message: elmTxtArea.value,
        MsgType: 'OUT',
        TO: ''
      };
      messages.value.push(msg);
      apzTextBox.value.textContent = '';
      placeCaretAtEnd(apzTextBox.value);
      canSend.value = true;
      scrollToBottom();
    }
  }, (response) => {
    canSend.value = true;
    if (response.data.error) {
      toast.error(response.data.error);
    } else {
      console.log(response);
    }
  }
  );
};

const getSenderName = (message) => {
  const userId = Number(user.value.ID) === 1 ? merchant.value.id : user.value.ID;
  return message.FROM.toString() === userId
    ? user.value.Username === merchant.value.name ? 'support' : user.value.Username
    : (message.ContactName === merchant.value.name ? 'support' : message.ContactName);
};

const initSocket = () => {
  const webSocket = new WebSocket('wss://cointopay.com/messages');
  // eslint-disable-next-line space-before-function-paren
  webSocket.onmessage = function (evt) {
    if (evt) {
      evt = evt.data.split(':');
      const id = evt[1];

      if (user.value) {
        const userId = Number(user.value.ID) === 1 ? 21901 : Number(user.value.ID);
        if (userId !== Number(user.value.ID)) {
          if (Number(evt[2]) === 21901) {
            const m = find(contacts.value, { id });
            if (m) {
              if (m.name === targetMerchant.value) {
                loadChatHistory(targetMerchant.value);
              } else {
                playSound();
              }
            } else {
              playSound();
              fetchContacts();
            }
          } else if (userId === Number(evt[2])) {
            loadChatHistory(targetMerchant.value);
          }
        }
      }
    }
  };
  // eslint-disable-next-line space-before-function-paren
  webSocket.onclose = function (evt) {
    initSocket();
  };
};

onBeforeMount(() => {
  initSocket();
  if (user.value) {
    if (Number(user.value.ID) === 1) {
      fetchContacts();
    } else {
      targetMerchant.value = merchant.value.name;
      loadChatHistory(targetMerchant.value);
    }
  }
});

onMounted(() => {
  if (apzTextBox.value) {
    apzTextBox.value.addEventListener('paste', (e) => {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      const text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      document.execCommand('insertHTML', false, text);
    });
  }
});
</script>

<template>
  <div class="fixed right-8 bottom-8 z-50">
    <div
      class="w-80 md:w-96 h-96 bg-gray-50 dark:bg-gray-800 dark:border-gray-700 rounded border border-gray-100 shadow-xl mb-3 p-3"
      v-show="isOpen">
      <div class="flex items-center justify-between pb-1">
        <h3 class="font-bold">Support Chat</h3>
        <button class="btn btn-primary btn-xs block ml-auto" @click="toggleOpen(false)">
          <Icon name="close" />
        </button>
      </div>
      <hr class="border-b border-gray-100 dark:border-gray-700" />
      <template v-if="user">
        <div class="panel-content">
          <label v-show="isShowNote"
            class="flex items-start gap-2 my-0.5 text-xs px-2 py-1 rounded border bg-blue-50 dark:bg-gray-800 border-blue-100 dark:border-gray-700">
            <span>
              Thanks for joining the chat. Please do not share any private information in this chat. This is purely for
              quick operational questions.
            </span>
            <button @click="isShowNote = false">
              <Icon name="close" classes="w-4 h-4" />
            </button>
          </label>
          <div class="py-2" v-if="user && Number(user.ID) === 1">
            <VueMultiselect v-model="selectedMerchant" :options="contacts" :searchable="true" track-by="name"
              label="label">
            </VueMultiselect>
          </div>
          <div class="chat-panel-body">
            <div class="chat-panel-cell">
              <div class="messages-container" ref="messagesContainer" v-if="messages.length > 0">
                <div class="conversation-box" v-for="message in messages" :key="message.ID">
                  <div class="rounded-lg p-3 border shadow text-sm max-w-[80%] text-gray-700"
                    :class="`${message.MsgType === 'OUT' ? 'bg-green-100 border-green-100 ml-auto' : 'bg-slate-100 dark:bg-slate-200 border-slate-100'}`">
                    <p class="mb-0">
                      <span class="cursor">
                        {{ getSenderName(message) }}:&nbsp;</span>
                      <span v-html="message.Message"></span>
                    </p>
                    <p class="mb-0 text-xs text-right">{{ formatTime(message.CreatedOn) }}</p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-b border-gray-100" />
          <!--Write message-->
          <div class="apz-row">
            <div class="write b-t">
              <form @keydown="submitOnEnter($event)" @keyup="toggleCtrlStatus($event)">
                <div class="apz-write-box" :class="{ 'error': !canSend }">
                  <div id="co" ref="apzTextBox" class="apz-text-box cursor-text" focus="true"
                    placeholder="Enter message..." contenteditable="true"></div>
                  <a href="javascript:void(0);" @click="send()" class="btn btn-primary" title="Send Message">Send</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="h-full flex items-center justify-center">
          <p class="text-gray-700">Please <router-link class="text-blue-500 hover:underline"
              @click.native="toggleOpen(false)" :to="{ name: 'login' }">login</router-link> to start Support Chat</p>
        </div>
      </template>
    </div>
    <div
      class="ml-auto w-24 h-9 bg-yellow-400 text-gray-700 rounded-full flex items-center justify-center gap-1 cursor-pointer hover:shadow-lg"
      @click="toggleOpen()">
      <Icon name="chat" />
      Chat
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-content {
  display: table;
  width: 100%;
  border-spacing: 0;
  outline: 0;
  table-layout: fixed;
  position: relative;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: calc(100% - 34px);
}

.chat-panel-body {
  padding: 0;
  position: relative;
  display: table-row;
  height: 100%;
}

.chat-panel-cell {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.messages-container {
  position: absolute;
  display: table-row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  box-sizing: border-box;
}

.apz-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.apz-row::after {
  content: "";
  clear: both;
  display: table;
}

ul.list {
  list-style: none;
  padding: 10px;
  min-height: 300px;
  overflow-y: auto;
  margin-bottom: 60px;
  height: calc(100vh - 410px);
}

ul.list>li {
  padding: 8px;
  border-bottom: 1px solid #EBEBEB;
  cursor: pointer;
}

ul.list>li:hover {
  background-color: #456cb4;
  color: #ffffff;
}

.conversation-box {
  font-size: 16px;
  position: relative;
  clear: both;
  margin-bottom: 12px;
  margin-top: 12px;
  vertical-align: top;
  word-break: break-word;
}

/* WRITE BOX */

.write {
  width: 100%;
  min-height: 60px;
  padding: 8px 0;
}

.apz-write-box {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px
}

.apz-write-box form {
  width: 100%;
}

.apz-text-box {
  border: 1px solid #EBEBEB;
  padding: 8px;
  width: calc(100% - 70px);
  outline: 0;
  background: transparent;
  font-size: 16px;
  max-height: 80px;
  overflow: auto;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  /* For Firefox */
}
</style>
