<template>
  <div v-click-away="() => { dropdown = false; }" class="relative">
    <button class="p-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 rounded dark:text-white dark:hover:bg-gray-500"
      @click="dropdown = !dropdown">
      <icon :classes="`w-5 h-5 ${nList.length > 0 ? 'text-red-500 animate-pulse' : ''}`" name="bell"></icon>
      <span v-if="nList.length > 0" class="badge badge-light" style="position: absolute;
        color: #fff;
        background-color: #f26c22;
        top: 3px;
        right: 3px;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 8px;
      ">{{ nList.length }}</span>
    </button>
    <div v-if="dropdown" aria-orientation="vertical"
      class="absolute overflow-y-auto top-8 right-0 mt-2 w-64 rounded-md shadow-lg py-2 bg-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 focus:outline-none"
      role="menu" tabindex="-1">
      <div class="relative">
        <div
          class="max-h-96 overflow-y-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
          <ul class="px-3">
            <li :class="`py-1 ${nList.length > 0 ? 'border-b border-gray-200 dark:border-gray-600' : ''}`">
              {{ `You have ${nList.length} ${nList.length === 1 ? 'notification' : 'notifications'}` }}
            </li>
            <li v-for="(notification, index) in nList" :key="index"
              class="py-1 border-b border-gray-200 dark:border-gray-600">
              <p class="flex items-center justify-between gap-2">
                <span href="javascript:void(0)">
                  {{ notification.message }}
                  <br />
                  <span class="text-sm font-normal text-gray-500 dark:text-gray-300" v-if="notification.diffToHuman">
                    {{ notification.diffToHuman }}
                  </span>
                </span>
                <span @click="removeItem(index)" class="cursor-pointer">
                  <svg height="24" width="24">
                    <circle cx="12" cy="12" r="5" stroke="#446bb3" stroke-width="2" fill="none" />
                  </svg>
                </span>
              </p>
              <p></p>
            </li>
            <li v-if="nList.length > 0" class="py-1 text-center hover:text-red-500">
              <button @click="clearAllNotifications()">Clear All</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as moment from 'moment-timezone';

export default {
  name: 'NotificationsDropdown',
  components: {
    Icon
  },
  setup() {
    const dropdown = ref(false);
    const interval = ref(null);
    const nList = ref([]);

    const store = useStore();
    const router = useRouter();

    // Computed
    const notifications = computed(() => store.getters.notifications);

    // Watch
    watch(() => ({ ...notifications.value }),
      (newVal, oldVal) => {
        format();
      });

    // Methods
    const markReadAndRedirect = (notification, index) => {
      if (index !== -1) {
        if (notification.id) {
          router.push({ name: 'wallet', params: { ln: notification.id } });
        }
      }
    };
    const clearAllNotifications = () => {
      try {
        store.commit('CLEAR_NOTIFICATIONS');
      } catch (error) {
        console.log(error);
        window.location.reload(true);
      }
    };
    const removeItem = (index) => {
      try {
        store.commit('REMOVE_NOTIFICATION', index);
      } catch (error) {
        console.log(error);
        window.location.reload(true);
      }
    };

    const dateDiffToHuman = (date) => {
      return date && moment.tz(date, 'UTC').fromNow();
    };

    const format = () => {
      nList.value = notifications?.value.map(n => {
        return {
          ...n,
          diffToHuman: n.date ? dateDiffToHuman(n.date) : ''
        };
      });
    };

    onBeforeMount(() => {
      interval.value = setInterval(() => {
        format();
      }, 1000);
    });

    onBeforeUnmount(() => {
      if (interval.value) {
        clearInterval(interval.value);
      }
    });

    return {
      dropdown,
      markReadAndRedirect,
      clearAllNotifications,
      dateDiffToHuman,
      removeItem,
      nList
    };
  }
};
</script>

<style lang="scss" scoped></style>
