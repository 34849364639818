<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import { inject, onBeforeMount, ref, watch } from 'vue';
import find from 'lodash/find';

export default {
  name: 'Tab',
  props: {
    name: { required: true },
    slug: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const isActive = ref(props.selected);
    const tabsProvider = inject('tabsProvider');
    const addTab = inject('addTab');
    const updateTab = inject('updateTab');
    watch(
      () => tabsProvider.activeTab,
      () => {
        isActive.value = props.slug === tabsProvider.activeTab;
      }
    );
    watch(() => props.name, (val) => {
      let tab = find(tabsProvider.tabs, { slug: props.slug });
      if (tab) {
        tab = {
          ...tab,
          name: val
        };
        updateTab(tab, tab.index);
      }
    });
    onBeforeMount(() => {
      addTab({
        name: props.name,
        index: tabsProvider.tabs.length,
        selected: props.selected,
        slug: props.slug
      });
    });
    return {
      isActive
    };
  }
};
</script>

<style scoped></style>
