<template>
  <centered-layout-empty>
    <div class="card">
      <h5 class="title">
        {{ translate('markets.wallet.withdraw.payout') }} - {{ wb?.balance + ' ' + wb?.longname }}
      </h5>
      <hr class="border-gray-300 dark:border-gray-600" />
      <div class="body">
        <div class="max-w-2xl">
          <form @submit.prevent="submit">
            <div class="mb-2">
              <label class="col-span-3 md:col-span-1">
                {{ translate('markets.wallet.withdraw.form.amount') }}
                <span class="ml-1 text-red-500">*</span>
              </label>
              <input type="text" class="form-input w-full" v-model="form.amount"
                :placeholder="translate('markets.wallet.withdraw.form.amount')" />
              <div class="text-red-500 text-sm">{{ showError(errorBag, 'amount') }}</div>
            </div>
            <div class="mb-2">
              <label class="col-span-3 md:col-span-1">
                {{ translate('markets.wallet.withdraw.form.payout_options') }}
                <span class="ml-1 text-red-500">*</span>
              </label>
              <div class="flex items-center justify-between">
                <select class="form-select w-full" v-model="form.type">
                  <option v-for="option in payoutOptions" :key="option.id" :value="option.id">{{ option.label }}
                  </option>
                </select>
                <span class="cursor-pointer" v-tippy="{ content: translate('markets.wallet.withdraw.info') }">
                  <Icon name="exclamation-circle" classes="w-8 h-8 ml-3 text-yellow-500" />
                </span>
              </div>
              <div class="text-red-500 text-sm">{{ showError(errorBag, 'type') }}</div>
            </div>
            <div class="mb-2" v-if="form.type === 'crypto-currency'">
              <label class="col-span-3 md:col-span-1">
                {{ translate('markets.wallet.withdraw.form.address') }}
                <span class="ml-1 text-red-500">*</span>
              </label>
              <input type="text" class="form-input w-full" v-model="form.address"
                :placeholder="translate('markets.wallet.withdraw.form.address')" />
              <div class="text-red-500 text-sm">{{ showError(errorBag, 'address') }}</div>
            </div>
            <div class="mb-2" v-if="form.type === 'crypto-currency' && wb.tag === '1'">
              <label class="col-span-3 md:col-span-1">
                Memo/Tag
                <span class="ml-1 text-red-500">*</span>
              </label>
              <input type="text" class="form-input w-full" v-model="form.tag" placeholder="Memo/Tag" />
              <div class="text-red-500 text-sm">{{ showError(errorBag, 'tag') }}</div>
            </div>
            <div class="mb-2" v-if="form.type === 'paypal'">
              <label class="col-span-3 md:col-span-1">
                {{ translate('markets.wallet.withdraw.form.paypal_email') }}
                <span class="ml-1 text-red-500">*</span>
              </label>
              <input type="email" class="form-input w-full" v-model="form.email"
                :placeholder="translate('markets.wallet.withdraw.form.paypal_email')" />
              <div class="text-red-500 text-sm">{{ showError(errorBag, 'email') }}</div>
            </div>
            <template v-if="form.type === 'bank'">
              <div class="mb-2">
                <label class="col-span-3 md:col-span-1">
                  {{ translate('markets.wallet.withdraw.form.account_title') }}
                  <span class="ml-1 text-red-500">*</span>
                </label>
                <input type="text" class="form-input w-full" v-model="form.username"
                  :placeholder="translate('markets.wallet.withdraw.form.account_title')" />
                <div class="text-red-500 text-sm">{{ showError(errorBag, 'username') }}</div>
              </div>
              <div class="mb-2">
                <label class="col-span-3 md:col-span-1">
                  {{ translate('markets.wallet.withdraw.form.iban') }}
                  <span class="ml-1 text-red-500">*</span>
                </label>
                <input type="text" class="form-input w-full" v-model="form.iban"
                  :placeholder="translate('markets.wallet.withdraw.form.iban')" />
                <div class="text-red-500 text-sm">{{ showError(errorBag, 'iban') }}</div>
              </div>
            </template>
            <button class="btn btn-success w-full mt-3" :disabled="isProcessing || !isValid">
              {{ translate('markets.wallet.withdraw.form.withdraw') }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </centered-layout-empty>
</template>

<script setup>
import CenteredLayoutEmpty from '@/components/layouts/CenteredLayoutEmpty.vue';
import { computed, inject, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import compositionUtils from '@/compositionUtils';
import validator from '@/validator';
import isNull from 'lodash/isNull';
import Icon from '@/components/Icon.vue';

const url = process.env.VUE_APP_API_URL;

const store = useStore();
const router = useRouter();

const translate = inject('translate');
const swal = inject('swal');
const toast = inject('toast');
const http = inject('http');

const wb = computed(() => store.state.wb);
const user = computed(() => store.state.user);
const isValid = computed(() => Object.keys(validate(form, rules)).length === 0);

const form = reactive({
  amount: wb.value.balance,
  type: '',
  address: '',
  tag: '',
  email: '',
  username: '',
  iban: ''
});
const isProcessing = ref(false);

const { numToPlain } = compositionUtils();

const payoutOptions = [
  {
    id: 'crypto-currency',
    label: 'Crypto currency'
  },
  {
    id: 'paypal',
    label: 'Paypal'
  },
  {
    id: 'bank',
    label: 'Bank'
  }
];
const errorBag = {};
const rules = {
  amount: ['required', 'regex:/^(\\d+)?([.]?\\d{1,8})?/'],
  type: ['required'],
  address: ['required_if:type,crypto-currency'],
  email: ['email', 'required_if:type,paypal'],
  username: ['required_if:type,bank'],
  iban: ['required_if:type,bank']
};
const {
  showError,
  validate
} = validator();

const submit = () => {
  isProcessing.value = true;
  const errorBag = validate(form, rules);
  if (Object.keys(errorBag).length === 0) {
    if (wb.value.balance < form.amount) {
      toast.error(translate('markets.wallet.withdraw.no_enough_funds'));
      isProcessing.value = false;
    } else {
      if (form.type === 'crypto-currency') {
        // GetSendToAddress Call
        const month = (new Date()).getMonth() + 1;
        let params = {
          MerchantID: user.value.ID,
          APIKey: user.value.APIKey,
          AltCoinID: wb.value.id,
          Amount: numToPlain(form.amount),
          PayoutMonth: month,
          coinAddress: form.address,
          ChargingMethod: 'fixedfee',
          TransactionTotal: 1,
          output: 'json'
        };
        if (wb.value.tag === '1') {
          params = {
            ...params,
            tag: form.tag
          };
        }
        http.get(`${url}/GetSendToAddress`, {
          headers: {
            'Content-Type': 'application/json'
          },
          params
        }).then(response => {
          isProcessing.value = false;
          // launch dialog
          swal.fire({
            text: translate('markets.wallet.withdraw.confirm_via_mail'),
            customClass: {
              container: 'swal2-tailwind'
            }
          });
        }).catch(error => {
          isProcessing.value = false;
          toast.error(error.data);
        });
      } else {
        const params = {
          Call: 'COIN-ON',
          MerchantID: user.value.ID,
          APIKey: user.value.APIKey,
          AltCoinID: wb.value.id,
          output: 'json'
        };
        http.get(`${url}/v2REAPI`, {
          headers: {
            'Content-Type': 'application/json'
          },
          params
        }).then(response => {
          if (response.data === 'OK') {
            const message = translate('markets.wallet.withdraw.min_limit');
            if (isNull(wb.value.balance) || wb.value.balance < 100) {
              toast.error(message);
              isProcessing.value = false;
            } else {
              let details = '';
              if (form.type === 'paypal') {
                details = 'PAYPAL-' + form.email;
              } else {
                details = 'BANK-' + form.username.trim() + '-IBAN_' + form.iban.replace(/ +/g, '');
              }

              let payload = {
                call: 'payout',
                PayoutType: 'FIAT',
                today: user.value.Username,
                APIKey: user.value.APIKey,
                AltCoinID: wb.value.id,
                amount: form.amount,
                output: 'json',
                PaymentDetails: details
              };
              if (form.type === 'paypal') {
                payload = {
                  ...payload,
                  email: form.email
                };
              } else if (form.type === 'bank') {
                payload = {
                  ...payload,
                  AccountHolderName: form.username.trim(),
                  IBAN: form.iban.replace(/ +/g, '')
                };
              }
              http.get(`${url}/tippr`, {
                headers: {
                  'Content-Type': 'application/json'
                },
                params: payload
              }).then(response => {
                isProcessing.value = false;
                if (response.data.result === 'OK') {
                  toast.success(translate('markets.wallet.withdraw.payout_scheduled'));
                  router.push('/wallet/balance');
                } else if (response.data.result === 'NOT OK') {
                  toast.error(response.data.result);
                }
              }).catch(error => {
                isProcessing.value = false;
                if (error.data && error.data.error) {
                  toast.error(error.data.error);
                }
              });
            }
          } else if (response.data === 'NOT OK') {
            isProcessing.value = false;
            toast.error(response.data);
          }
        });
      }
    }
  }
};

onBeforeMount(() => {
  if (!wb.value) {
    router.push('/wallet/balance');
  }
});

</script>

<style scoped></style>
