<template>
  <div class="mb-0.5">
    <div v-for="count in counter" :key="count"
         class="animate-pulse h-16 shadow bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-900 rounded flex items-center justify-between px-3">
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-32"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-16"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-24"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-32"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-32"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-32"></div>
      <div class="h-2 bg-gray-200 dark:bg-gray-500 rounded w-32"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketsSkeleton',
  props: ['counter']
};
</script>

<style scoped>

</style>
