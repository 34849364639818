import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mitt from 'mitt';
import LitepieDatepicker from 'litepie-datepicker-tw3';
import Toaster from '@meforma/vue-toaster';
import VueClickAway from 'vue3-click-away';
import HighchartsVue from 'highcharts-vue';
import VueTippy from 'vue-tippy';
import translate from './libs/translate';
import instance from './libs/axios';
import { globalCookiesConfig } from 'vue3-cookies';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'tippy.js/dist/tippy.css';
import { quillEditor, Quill } from 'vue3-quill';
import htmlEditButton from 'quill-html-edit-button';

// Tailwind CSS v2
import './assets/tailwind.scss';

Quill.register('modules/htmlEditButton', htmlEditButton);

globalCookiesConfig({
  expireTimes: '7d',
  path: '/',
  domain: '.cointopay.com',
  secure: true,
  sameSite: 'None'
});

const app = createApp(App)
  .use(VueTippy, {
    directive: 'tippy',
    component: 'tippy',
    defaultProps: {
      allowHTML: true,
      placement: 'top'
    },
    flipDuration: 0,
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: false
        }
      }
    }
  })
  .use(store)
  .use(router)
  .use(LitepieDatepicker)
  .use(Toaster, {
    dismissible: false,
    maxToasts: 2
  })
  .use(VueClickAway)
  .use(HighchartsVue, { tagName: 'highcharts' })
  .use(VueSweetalert2)
  .use(quillEditor);

app.config.isCustomElement = (tag) => tag === 'trix-editor';

// Providers
app.provide('toast', app.config.globalProperties.$toast);
app.provide('http', instance);
app.provide('translate', translate);
app.provide('swal', app.config.globalProperties.$swal);
app.provide('emitter', mitt());
// Global Properties
app.config.globalProperties.$http = instance;

app.mount('#app');
