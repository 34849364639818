<template>
  <div class="p-4 bg-white dark:bg-gray-800 rounded shadow">
    <div class="max-w-7xl 3xl:max-w-8xl mx-auto" v-if="baseMarkets.length">
      <TabSet full="" :action="true" @changed="setBaseMarket">
        <template v-for="baseMarket in baseMarkets" :key="baseMarket.name">
          <Tab :name="`${baseMarket.name.toUpperCase()} Market`" :slug="`market-${baseMarket.name.toLowerCase()}`"
            :selected="baseMarket.isSelected">
            <MarketsList :isLoading="isLoading" />
          </Tab>
        </template>
      </TabSet>
    </div>
  </div>
</template>

<script setup>
import TabSet from '@/components/common/TabSet.vue';
import Tab from '@/components/common/Tab.vue';
import MarketsList from '@/components/exchange/markets/MarketsList.vue';
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import { FETCH_MARKETS, SELECT_BASE_MARKET } from '@/store/keys';
import startsWith from 'lodash/startsWith';
import find from 'lodash/find';
const store = useStore();

const isLoading = ref(false);
let socket = null;
let traderSocket = null;
let tradingSocket = null;
let isSocketDestroyed = false;

const baseMarkets = computed(() => store.state.baseMarkets);
const baseMarket = computed(() => baseMarkets.value.filter(b => b.isSelected)[0]);
const fetchCurrency = (altCoinID) => {
  if (baseMarket.value) {
    store.dispatch(FETCH_MARKETS, baseMarket.value.name, altCoinID).then(() => { });
  }
};

const setBaseMarket = (tab) => {
  const marketName = tab.tab.name.replace(' Market', '');
  const baseMarket = find(baseMarkets.value, (b) => {
    return b.name.toLowerCase() === marketName.toLowerCase();
  });
  if (baseMarket) {
    const baseMarketsList = baseMarkets.value.map(b => {
      return {
        ...b,
        isSelected: b.name.toLowerCase() === baseMarket.name.toLowerCase()
      };
    });
    store.commit(SELECT_BASE_MARKET, baseMarketsList);
    isLoading.value = true;
    store.dispatch(FETCH_MARKETS, baseMarket.name).then(() => { isLoading.value = false; });
  }
};

const initSocketTrade = () => {
  socket = new WebSocket('wss://wss.cointopay.com/trade');
  socket.onmessage = (evt) => {
    if (evt) {
      const data = evt.data;
      if (
        startsWith(data, 'BUY') ||
        startsWith(data, 'SELL') ||
        startsWith(data, 'TRADE') ||
        startsWith(data, 'CANCEL')
      ) {
        if (!isLoading.value) {
          const parts = data.split(/[:,]+/);
          if (parts.length > 0) {
            fetchCurrency(parts[1]);
          }
        }
      }
    }
  };

  socket.onclose = (evt) => {
    if (!isSocketDestroyed) {
      initSocketTrade();
    }
  };
};

const initSocketTrading = () => {
  tradingSocket = new WebSocket('wss://wss.cointopay.com/trading');
  tradingSocket.onmessage = (evt) => {
    if (evt) {
      const data = evt.data;
      if (
        startsWith(data, 'BUY') ||
        startsWith(data, 'SELL') ||
        startsWith(data, 'TRADE') ||
        startsWith(data, 'CANCEL')
      ) {
        if (!isLoading.value) {
          const parts = data.split(/[:,]+/);
          if (parts.length > 0) {
            fetchCurrency(parts[1]);
          }
        }
      }
    }
  };

  tradingSocket.onclose = (evt) => {
    if (!isSocketDestroyed) {
      initSocketTrading();
    }
  };
};

const initSocketTrader = () => {
  traderSocket = new WebSocket('wss://artemis.cointopay.com/trading/trader');
  traderSocket.onmessage = (evt) => {
    if (evt) {
      let data = evt.data;
      if (data) {
        data = JSON.parse(data);
        if (['BUY', 'SELL', 'TRADE', 'CANCEL'].indexOf(data.Action) !== -1) {
          if (!isLoading.value) {
            fetchCurrency(data.AltCoinID);
          }
        }
      }
    }
  };
  traderSocket.onclose = (evt) => {
    if (!isSocketDestroyed) {
      initSocketTrader();
    }
  };
};

onBeforeMount(() => {
  isLoading.value = true;
  store.dispatch(FETCH_MARKETS, baseMarket.value.name).then(() => { isLoading.value = false; });
  initSocketTrade();
  initSocketTrading();
  initSocketTrader();
});

onBeforeUnmount(() => {
  // Close socket
  if (socket) {
    isSocketDestroyed = true;
    socket.close();
  }
  if (tradingSocket) {
    isSocketDestroyed = true;
    tradingSocket.close();
  }
  if (traderSocket) {
    isSocketDestroyed = true;
    traderSocket.close();
  }
});

</script>

<style scoped lang="scss">
table {
  tbody {
    tr {
      td {
        @apply py-2;
      }
    }
  }
}
</style>
