export const SET_MODE = 'SET_MODE';
export const SET_APP_VERSION = 'SET_APP_VERSION';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const UNSET_LOGGED_IN = 'UNSET_LOGGED_IN';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_LOCALE = 'SET_LOCALE';
export const PERSIST_AUTH = 'PERSIST_AUTH';
export const SET_USER = 'SET_USER';
export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO';
export const SAVE_ACCOUNT_INFO = 'SAVE_ACCOUNT_INFO';
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const FETCH_INPUT_CURRENCIES = 'FETCH_INPUT_CURRENCIES';
export const SET_INPUT_CURRENCIES = 'SET_INPUT_CURRENCIES';
export const SET_BALANCES = 'SET_BALANCES';
export const FETCH_ALT_COINS = 'FETCH_ALT_COINS';
export const SET_ALT_COINS = 'SET_ALT_COINS';
export const SET_ALL_ALT_COINS = 'SET_ALL_ALT_COINS';
export const SET_CONVERTER = 'SET_CONVERTER';
export const FETCH_TRANSFER_WISE_CONFIG = 'FETCH_TRANSFER_WISE_CONFIG';
export const SET_TRANSFER_WISE_CONFIG = 'SET_TRANSFER_WISE_CONFIG';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const FETCH_ADDRESS_BOOK = 'FETCH_ADDRESS_BOOK';
export const SET_ADDRESS_BOOK = 'SET_ADDRESS_BOOK';
export const SET_REFRESH_DATA = 'SET_REFRESH_DATA';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const FETCH_POS_DATA = 'FETCH_POS_DATA';
export const SAVE_TICKETS = 'SAVE_TICKETS';
export const SET_POS = 'SET_POS';
export const SET_POS_CALLS = 'SET_POS_CALLS';
export const SET_WEB_LOGGED_IN_EMPLOYEE = 'SET_WEB_LOGGED_IN_EMPLOYEE';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const SAVE_PRODUCTS = 'SAVE_PRODUCTS';
export const SAVE_RECEIPTS = 'SAVE_RECEIPTS';
export const SAVE_RECEIPT_ITEMS = 'SAVE_RECEIPT_ITEMS';
export const SAVE_EMPLOYEES = 'SAVE_EMPLOYEES';
export const FETCH_SHOP_DATA = 'FETCH_SHOP_DATA';
export const FETCH_SHOP_DETAIL = 'FETCH_SHOP_DETAIL';
export const SET_SHOP_DATA = 'SET_SHOP_DATA';
export const SET_SHOP_DETAIL = 'SET_SHOP_DETAIL';
export const FETCH_COUPONS = 'FETCH_COUPONS';
export const SET_COUPONS = 'SET_COUPONS';
export const SET_INVOICE = 'SET_INVOICE';
export const FETCH_SHIPPING_METHODS = 'FETCH_SHIPPING_METHODS';
export const SAVE_SHIPPING_METHODS = 'SAVE_SHIPPING_METHODS';
export const SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS';
export const SET_SHIPPING_METHOD_ACTION = 'SET_SHIPPING_METHOD_ACTION';
export const FETCH_SHOPS_LIST = 'FETCH_SHOPS_LIST';
export const SET_SHOP_CATEGORIES = 'SET_SHOP_CATEGORIES';
export const SET_SHOPS_LIST = 'SET_SHOPS_LIST';
export const SET_SHOP_ID = 'SET_SHOP_ID';
export const FETCH_RATINGS = 'FETCH_RATINGS';
export const SET_PRODUCTS_FILTERS = 'SET_PRODUCTS_FILTERS';
export const UPDATE_CART = 'UPDATE_CART';
export const FETCH_MARKETS = 'FETCH_MARKETS';
export const SELECT_BASE_MARKET = 'SELECT_BASE_MARKET';
export const SET_MARKETS = 'SET_MARKETS';
export const SET_MARKET = 'SET_MARKET';
export const SET_SITE = 'SET_SITE';
export const SET_SITE_DATA = 'SET_SITE_DATA';
export const SET_WITHDRAW_BALANCE = 'SET_WITHDRAW_BALANCE';
export const SET_FAVORITE_MARKETS = 'SET_FAVORITE_MARKETS';
export const GENERATE_JWT_TOKEN = 'GENERATE_JWT_TOKEN';
export const SET_JWT_KEY = 'SET_JWT_KEY';
export const SAVE_SYMBOLS = 'SAVE_SYMBOLS';
export const SAVE_TICKERS = 'SAVE_TICKERS';
export const LOGOUT = 'LOGOUT';
