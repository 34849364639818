import axios from 'axios';
import store from '../store';
import { LOGOUT, SAVE_ACCOUNT_INFO, SET_BALANCES, SET_USER } from '@/store/keys';

const instance = axios.create({
  withCredentials: true
});
// Response Interceptor
instance.interceptors.response.use((response) => {
  let message = '';
  if (response.data?.reason) {
    message = response.data?.reason;
  }
  if (typeof response.data === 'string') {
    message = response.data;
  }
  if (response.data?.message) {
    message = response.data?.message;
  }
  const messages = [
    'APIKey has changed',
    'authentication failed',
    'api authentication failed',
    'No valid APIKey available'
  ];

  if (messages.indexOf(message) !== -1) {
    store.dispatch(LOGOUT).then(() => {
      const state = store.state;
      const newState = {};
      const initialState = {
        ...state,
        isLoggedIn: false,
        isSocialLogin: false,
        currentRoute: '',
        user: null,
        accountInfo: null,
        addressBook: {
          crypto: {},
          transferwise: {}
        },
        notifications: [],
        balances: [],
        pos: {
          categories: [],
          products: [],
          favourites: [],
          tickets: [],
          receipts: [],
          receiptItems: [],
          users: []
        },
        posCalls: {
          categories: '',
          products: '',
          tickets: '',
          receipts: '',
          receiptItems: '',
          users: ''
        },
        webLoggedInEmployee: null,
        shopsList: [],
        shopCategories: [],
        shopId: '',
        shopData: {
          categories: [],
          products: []
        },
        coupons: [],
        shippingMethods: [],
        shippingMethodAction: 'INSERT',
        productsFilters: {
          currentPage: 1,
          keyword: '',
          category: ''
        },
        cart: {
          items: [],
          merchantId: null
        },
        invoice: null,
        sitename: '',
        siteData: null,
        jwt: {
          token: null,
          expiry: null
        }
      };
      Object.keys(state).forEach(key => {
        newState[key] = initialState[key];
      });
      store.replaceState(newState);
      store.commit(SET_USER, null);
      store.commit(SAVE_ACCOUNT_INFO, null);
      store.commit(SET_BALANCES, []);
      window.location.href = '/login';
    });
  }
  return response;
}, (error) => {
  return Promise.reject(error.response);
});

export default instance;
