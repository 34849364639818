<template>
  <div class="card">
    <h5 class="title">{{ translate('markets.latest_comments') }}</h5>
    <hr class="border-gray-300 dark:border-gray-600" />
    <div class="body p-4">
      <div ref="commentsList"
        class="scrollbar overflow-y-scroll h-72 scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
        <div v-for="(comment, index) in comments" :key="index" class="break-words">
          {{ comment }}
        </div>
      </div>
      <div class="mt-3">
        <form @submit.prevent="send()">
          <div class="flex items-center">
            <input type="text" class="form-input w-full rounded-r-none border-r-0" v-model="message"
              :placeholder="translate('markets.type_here')" />
            <button class="btn btn-primary py-3 rounded-l-none">{{ translate('markets.send') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>

import { computed, inject, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const translate = inject('translate');

const webSocketChat = ref(null);
const comments = ref([]);
const isSocketDestroyed = ref(false);
const commentsList = ref(null);
const message = ref('');

const user = computed(() => store.state.user);

// Send message
const send = () => {
  if (message.value) {
    webSocketChat.value.send(message.value);
    message.value = '';
  }
};

const initSocketChat = () => {
  webSocketChat.value = new WebSocket('wss://wss.cointopay.com/echo');
  webSocketChat.value.onmessage = function(evt) {
    if (evt) {
      const data = evt.data;
      comments.value.push(data);
      setTimeout(() => {
        commentsList.value.scrollTop = commentsList.value.scrollHeight;
      }, 50);
    }
  };
  webSocketChat.value.onopen = function(evt) {
    if (user.value) {
      webSocketChat.value.send(`/nick ${user.value.Username}`);
    }
  };
  webSocketChat.value.onclose = function(evt) {
    if (!isSocketDestroyed.value) {
      initSocketChat();
    }
  };
};

onBeforeMount(() => {
  initSocketChat();
});

onBeforeUnmount(() => {
  if (webSocketChat.value) {
    isSocketDestroyed.value = true;
    webSocketChat.value.close();
  }
});
</script>

<style scoped></style>
