<template>
  <div class="mb-3 flex justify-end">
    <div class="relative">
      <Icon classes="absolute w-5 h-5 top-3 left-3 dark:text-gray-300" name="search"></Icon>
      <input v-model="keyword" aria-label="Search" class="form-input px-10 w-full" type="text" />
      <span v-show="keyword !== ''" class="absolute top-3 right-3" @click="keyword = ''">
        <Icon classes="w-5 h-5 dark:text-gray-300" name="close"></Icon>
      </span>
    </div>
  </div>
  <div
    class="overflow-auto scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-track-gray-900 dark:scrollbar-thumb-gray-600">
    <table class="mb-2 table select-none" v-if="!isLoading">
      <thead>
        <tr>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Market'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.market') }}
                <span class="ml-1" v-if="state.sortKey === 'Market'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Name'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.currency') }}
                <span class="ml-1" v-if="state.sortKey === 'Currency'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer"
              @click="state.sortKey = 'PercentageChange'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.change') }}
                <span class="ml-1" v-if="state.sortKey === 'PercentageChange'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'LastPrice'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.last') }}
                <span class="ml-1" v-if="state.sortKey === 'LastPrice'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Last24hHigh'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.24h_high') }}
                <span class="ml-1" v-if="state.sortKey === 'Last24hHigh'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Last24hLow'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.24h_low') }}
                <span class="ml-1" v-if="state.sortKey === 'Last24hLow'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Volume'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.volume') }}
                <span class="ml-1" v-if="state.sortKey === 'Volume'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
          <th>
            <span class="cursor-pointer" @click="state.sortKey = 'Time'; state.sortReverse = !state.sortReverse">
              <span class="inline-flex items-center">
                {{ translate('markets.refresh_date') }}
                <span class="ml-1" v-if="state.sortKey === 'Time'">
                  <Icon name="asc" v-if="!state.sortReverse" />
                  <Icon name="desc" v-else />
                </span>
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="record.id" :class="`cursor-pointer ${record.isUpdated ? 'glow' : ''}`"
          @click="selectMarket(record)">
          <td>{{ record.Market }}</td>
          <td>{{ record.Name }}</td>
          <td
            :class="`${record.PercentageChange < 0 ? 'text-red-600 dark:text-red-600' : 'text-green-600 dark:text-green-600'}`">
            {{ record.PercentageChange }}%</td>
          <td>{{ record.LastPrice }}</td>
          <td>{{ record.Last24hHigh }}</td>
          <td>{{ record.Last24hLow }}</td>
          <td>{{ record.Volume }}</td>
          <td>{{ formatTime(record.Time) }}</td>
        </tr>
      </tbody>
    </table>
    <markets-skeleton :counter="4" v-else></markets-skeleton>
  </div>
</template>

<script setup>
import Icon from '@/components/Icon.vue';
import MarketsSkeleton from '@/components/MarketsSkeleton.vue';
import { useStore } from 'vuex';
import { inject, computed, reactive, ref } from 'vue';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import compositionUtils from '@/compositionUtils';

import { useRouter } from 'vue-router';
import { SET_MARKET } from '@/store/keys';
const { formatTime } = compositionUtils();

defineProps({
  isLoading: {
    type: Boolean,
    default: false
  }
});

const keyword = ref('');

const store = useStore();
const router = useRouter();

const translate = inject('translate');

const state = reactive({
  keyword: '',
  sortKey: '',
  sortReverse: false
});

const records = computed(() => {
  let markets = [...store.state.markets];
  if (keyword.value !== '') {
    const q = keyword.value.toLowerCase();
    markets = markets.filter(m => {
      return (
        includes(m.Name.toLowerCase(), q) ||
        includes(m.Market.toLowerCase(), q)
      );
    });
  }
  return orderBy(markets, state.sortKey, state.sortReverse ? 'desc' : 'asc');
});

const selectMarket = (record) => {
  store.commit(SET_MARKET, record);
  router.push('/tradeview/' + record.Market);
};
</script>
