import {
  LOGOUT,
  SAVE_ACCOUNT_INFO,
  SET_BALANCES,
  SET_NOTIFICATIONS,
  SET_USER
} from './store/keys';
import { useStore } from 'vuex';
import { computed, inject } from 'vue';
import * as _ from 'lodash';
import { Fancybox } from '@fancyapps/ui';
import Facebook from './libs/Facebook';
import moment from 'moment-timezone';
// const moment = require('moment-timezone/builds/moment-timezone-with-data-2012-2022.min')

// eslint-disable-next-line space-before-function-paren
export default function () {
  const toast = inject('toast');
  const store = useStore();

  const transactionStatuses = [
    {
      value: 'waiting',
      label: 'Waiting'
    },
    {
      value: 'paid',
      label: 'Paid'
    },
    {
      value: 'underpaid',
      label: 'Underpaid'
    },
    {
      value: 'payout',
      label: 'Payout'
    },
    {
      value: 'expired',
      label: 'Expired'
    },
    {
      value: 'swap',
      label: 'Swap'
    },
    {
      value: 'scheduled',
      label: 'Scheduled'
    },
    {
      value: 'vtoken-active',
      label: 'vToken-Active'
    },
    {
      value: 'vtoken-claimed',
      label: 'vToken-Claimed'
    },
    {
      value: 'ltr-open',
      label: 'LTR-open'
    },
    {
      value: 'ltr-expired',
      label: 'LTR-expired'
    },
    {
      value: 'ltr-closed',
      label: 'LTR-closed'
    },
    {
      value: 'awaiting-fiat',
      label: 'Awaiting-fiat'
    }
  ];

  const cartStatusesList = (status = null) => {
    const statuses = [
      {
        id: 1,
        value: 'Not Set'
      },
      {
        id: 2,
        value: 'Processing'
      },
      {
        id: 3,
        value: 'Processed'
      },
      {
        id: 4,
        value: 'Shipped'
      },
      {
        id: 5,
        value: 'Reversed'
      },
      {
        id: 6,
        value: 'Refunded'
      },
      {
        id: 7,
        value: 'Completed'
      },
      {
        id: 8,
        value: 'Waiting'
      }
    ];
    if (status) {
      return _.find(statuses, { id: Number(status) });
    }
    return statuses;
  };

  // Generate icon url
  const generateIconUrl = (name) => {
    if (!name) {
      return '';
    }
    const iconName = name.toLowerCase();
    return `https://s3-eu-west-1.amazonaws.com/cointopay/img/${iconName}_dash2.png`;
  };
  // Save notification to vuex store
  const saveNotification = (msg) => {
    const notifications = computed(() => store.state.notifications);
    notifications.value.splice(0, 0, {
      message: msg,
      date: moment()
    });
    store.commit(SET_NOTIFICATIONS, notifications);
  };
  // Copy data to clipboard
  const copyToClipboard = (val, isShowAlert = true) => {
    navigator.clipboard.writeText(val).then(
      () => {
        if (isShowAlert) {
          toast.success('Copied to clipboard');
        }
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const toFixed = (num) => {
    return Number(num).toFixed(8);
  };

  // Format date time
  const formatDT = (dateTime) => {
    if (dateTime === null || dateTime === '-') {
      return '-';
    }
    dateTime = dateTime.replace(/-/g, '/');
    return moment(new Date(dateTime)).locale(store.state.locale).format('LLL');
  };

  const formatTime = (time) => {
    return moment(new Date(time))
      .locale(store.state.locale)
      .format('yyyy-MM-DD HH:mm:ss');
  };

  // Format price
  const formatPrice = (value, currency = 'EUR') => {
    if (!value) {
      return '';
    }
    const currencies = {
      VPO: 'EUR'
    };
    currency =
      Object.keys(currencies).indexOf(currency) !== -1
        ? currencies[currency]
        : currency;
    return value + ' ' + currency;
  };

  const numToPlain = (num, decimal = 8) => {
    return Number(num).toFixed(decimal).toString();
  };

  // Check if provided string is a valid url
  const isValidUrl = (str) => {
    const a = document.createElement('a');
    a.href = str;
    return a.host && a.host !== window.location.host;
  };

  const lowercase = (val) => {
    return val ? val.toLowerCase() : val;
  };

  // Generate id
  const generateId = (items, key = 'ID') => {
    if (items.length > 0) {
      const item = _.maxBy(items, (item) => {
        return Number(item[key]);
      });
      return (Number(item[key]) + 1).toString();
    }
    return '1';
  };

  const fancyBox = (url) => {
    Fancybox.show(
      [
        {
          thumb: url,
          src: url
        }
      ],
      {}
    );
  };

  const baseMarketName = (name) => {
    switch (name.toLowerCase()) {
      case 'btc':
        return 'btc';
      case 'eurx':
      case 'erx':
        return 'erx';
      case 'doge':
      case 'dog':
        return 'dog';
      case 'ltc':
        return 'ltc';
    }
  };

  const isJWTExpired = (expiry) => {
    if (expiry === null || expiry === undefined) {
      return true;
    } else {
      const now = moment.utc().valueOf();
      expiry = moment.utc(expiry, 'MMM DD, YYYY h:mm:ss A').valueOf();
      return expiry < now;
    }
  };

  // Logout
  const terminateSession = () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch(LOGOUT)
        .then((response) => {
          if (response.toLowerCase() === 'success') {
            if (store.state.isSocialLogin) {
              // logout from facebook
              const facebook = new Facebook(process.env.VUE_APP_FACEBOOK_ID);
              if (facebook) {
                facebook
                  .initialize()
                  .then((response) => {})
                  .catch((error) => {
                    console.log(error);
                  });
                facebook.signOut().then(() => {});
              }
            }
            const state = store.state;
            const newState = {};
            const initialState = {
              ...state,
              isLoggedIn: false,
              isSocialLogin: false,
              currentRoute: '',
              user: null,
              accountInfo: null,
              addressBook: {
                crypto: {},
                transferwise: {}
              },
              notifications: [],
              balances: [],
              pos: {
                categories: [],
                products: [],
                favourites: [],
                tickets: [],
                receipts: [],
                receiptItems: [],
                users: []
              },
              posCalls: {
                categories: '',
                products: '',
                tickets: '',
                receipts: '',
                receiptItems: '',
                users: ''
              },
              webLoggedInEmployee: null,
              shopsList: [],
              shopCategories: [],
              shopId: '',
              shop: null,
              shopData: {
                categories: [],
                products: []
              },
              baseMarkets: [
                { id: 1, name: 'BTC', fullName: 'bitcoin', isSelected: true },
                { id: 2, name: 'LTC', fullName: 'litecoin', isSelected: false },
                {
                  id: 9,
                  name: 'Doge',
                  fullName: 'dogecoin',
                  isSelected: false
                },
                {
                  id: 726,
                  name: 'EURx',
                  fullName: 'banckrypto',
                  isSelected: false
                }
              ],
              coupons: [],
              shippingMethods: [],
              shippingMethodAction: 'INSERT',
              productsFilters: {
                currentPage: 1,
                keyword: '',
                category: ''
              },
              cart: {
                items: [],
                merchantId: null
              },
              invoice: null,
              siteData: null
            };
            Object.keys(state).forEach((key) => {
              newState[key] = initialState[key];
            });
            store.replaceState(newState);
            store.commit(SET_USER, null);
            store.commit(SAVE_ACCOUNT_INFO, null);
            store.commit(SET_BALANCES, []);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    transactionStatuses,
    cartStatusesList,
    generateIconUrl,
    saveNotification,
    copyToClipboard,
    toFixed,
    formatDT,
    formatTime,
    formatPrice,
    numToPlain,
    isValidUrl,
    lowercase,
    generateId,
    fancyBox,
    baseMarketName,
    isJWTExpired,
    terminateSession
  };
}
