<template>
  <centered-layout-empty>
    <TabSet full="" :active="tab" @changed="updateRoute">
      <Tab :name="translate('markets.wallet.tabs.balance')" slug="balance" :selected="true">
        <balance @depositResponse="updateDeposit" :refresh="refresh" :tab="tab"></balance>
      </Tab>
      <Tab :name="translate('markets.wallet.tabs.open_orders')" slug="open-orders">
        <open-orders :refresh="refresh" :tab="tab"></open-orders>
      </Tab>
      <Tab :name="translate('markets.wallet.tabs.history')" slug="history">
        <OrdersHistory :refresh="refresh" :tab="tab"></OrdersHistory>
      </Tab>
      <Tab :name="translate('markets.wallet.tabs.transactions')" slug="transactions">
        <transactions :refresh="refresh" :tab="tab"></transactions>
      </Tab>
    </TabSet>
  </centered-layout-empty>
  <!-- Login Modal -->
  <login-modal></login-modal>
</template>

<script setup>
import CenteredLayoutEmpty from '@/components/layouts/CenteredLayoutEmpty.vue';
import TabSet from '@/components/common/TabSet.vue';
import Tab from '@/components/common/Tab.vue';
import { computed, inject, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import Balance from '@/components/exchange-wallet/Balance.vue';
import LoginModal from '@/components/auth/modals/LoginModal.vue';
import OpenOrders from '@/components/exchange-wallet/OpenOrders.vue';
import OrdersHistory from '@/components/exchange-wallet/OrdersHistory.vue';
import Transactions from '@/components/exchange-wallet/Transactions.vue';
import { useStore } from 'vuex';
import endsWith from 'lodash/endsWith';
import { useRoute, useRouter } from 'vue-router';

const translate = inject('translate');
const toast = inject('toast');

const route = useRoute();
const router = useRouter();
const store = useStore();

const user = computed(() => store.state.user);
const refresh = computed(() => timer.value);

const timer = ref(null);
const depositResponse = ref(null);
let socket = null;
const isSocketDestroyed = ref(false);
const currentTransaction = ref({
  id: 0,
  status: ''
});
const tab = ref(null);

const updateDeposit = (depositResponse) => {
  depositResponse.value = depositResponse;
};

const initSocket = () => {
  socket = new WebSocket('wss://wss.cointopay.com/balance');
  socket.onmessage = (evt) => {
    if (evt) {
      if (evt.data) {
        const parts = evt.data.split(':');
        if (parts.length >= 3) {
          if (parts[0].toString() === user.value.ID.toString()) {
            // Get transactions
            timer.value = (new Date()).getTime();
          }
          currentTransaction.value.id = parts[1];
          currentTransaction.value.status = parts[2];
          if (depositResponse.value) {
            const transactionID = depositResponse.value.TransactionID;
            if (endsWith(transactionID.toString(), currentTransaction.value.id.toString())) {
              // Close deposit modal
              if (currentTransaction.value.status === 'COMPLETE') {
                toast.success(`Transaction ${transactionID} complete`);
              } else if (currentTransaction.value.status === 'FAILED') {
                toast.error(`Transaction ${transactionID} failed`);
              }
            }
          }
        }
      }
    }
  };
  socket.onclose = (evt) => {
    if (!isSocketDestroyed.value) {
      initSocket();
    }
  };
};

const slugToName = (slug) => {
  let name = 'Balance';
  switch (slug) {
    case 'balance':
      name = 'balance';
      break;
    case 'open-orders':
      name = 'open_orders';
      break;
    case 'transactions':
      name = 'transactions';
      break;
    case 'history':
      name = 'history';
      break;
  }
  return name;
};

const updateRoute = (t) => {
  tab.value = t.tab.slug;
  router.replace({
    name: router.currentRoute.name,
    params: {
      tab: tab.value
    }
  });
  timer.value = (new Date()).getTime();
};

onBeforeMount(() => {
  if (route.params.tab) {
    const n = slugToName(route.params.tab);
    tab.value = translate(`markets.wallet.tabs.${n}`);
  }
  initSocket();
});

onBeforeUnmount(() => {
  if (socket) {
    isSocketDestroyed.value = true;
    socket.close();
  }
});

</script>

<style scoped></style>
